

import React, { useEffect } from 'react';

import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Suspense } from 'react';

import Instruments from 'src/pages/Instruments';
import AppSettings from 'src/pages/Settings';

import NotFound from 'src/pages/NotFound';
import { routes, history } from 'src/router';
import { IAuth, INotification, Notifications } from 'src/libs';
import MultiNotifications from '../MultiNotifications';
import api from 'src/api';

const Consultations = React.lazy(() => import('src/pages/Consultations'));
const Dashboard = React.lazy(() => import('src/pages/Dashboard'));
const Meeting = React.lazy(() => import('src/pages/Consultations/Meeting'));
const Courses = React.lazy(() => import('src/pages/Courses'));

export default function RouterSwitch({
  auth
}: {
  auth: IAuth
}) {
  const [notifications, setNotifications] = React.useState<INotification[]>([]);

  useEffect(() => {
    const ntfs: INotification[] = auth.notifications;
    const ntfs_: INotification[] = ntfs.filter(incN =>
      incN.id !== 'private_group' &&
      !incN.actions?.includes('update') &&
      !notifications.some(stN => incN.id === stN.id)
    );
    
    setNotifications(ntfs_);
  }, [auth])

  const onCloseNotification = (items: INotification[]) => {
    api.closeNotifications(items.map(n => ({
      id: n.id,
      value: n.value
    })));
    auth.notifications = notifications.slice(items.length);
    setNotifications(auth.notifications);
  };
      
  return <>
    <Router history={history}>
      <Switch>
        <Redirect from='/' exact to={routes.Dashboard.path} />

        <Route path={routes.Instruments.path} exact component={Instruments} />
        <Route path={routes.Dashboard.path} exact render={() => (<Suspense fallback={<div></div>}><Dashboard/></Suspense>)} />

        <Redirect from={routes.AppSettings.path} exact to={`${routes.AppSettings.path}/payments`} />
        <Route path={`${routes.AppSettings.path}/:section([A-Za-z]+)?`} exact component={AppSettings}/>
        <Route path={`${routes.AppSettings.path}/:section/:subsection`} exact component={AppSettings} />

        <Route path={routes.Consultations.path} exact render={() => (<Suspense fallback={<div></div>}><Consultations/></Suspense>)} />
        <Route path={routes.Meeting.path} exact render={() => (<Suspense fallback={<div></div>}><Meeting/></Suspense>)} />

        <Route path={routes.Courses.path} render={() => (<Suspense fallback={<div></div>}><Courses/></Suspense>)} />

        <Route component={NotFound} />
      </Switch>
      
      {notifications.length > 0 && <MultiNotifications
        items={notifications}
        onClose={onCloseNotification}
      />}

    </Router>
    
    <Notifications />
  </>
}