import { Input, CircleMode, IFormData, PrognosticsMode, SynastryMode, ISynastryPartnerData } from 'src/libs'
// import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { SwapIcon } from 'src/assets/icons/system'
import { EyeIcon, EyeCrossedIcon } from 'src/assets/icons/maps'
import { useTranslation } from 'src/i18n/useTranslation'
import { IExtendedMap } from 'src/pages/Instruments/Maps'
import { isCompatibility, isPartner, isRelocation, progressionsTitle, prognosticModes, isPrognostics } from 'src/pages/Instruments/utils'
import store from 'src/store'
import styled, { css } from 'styled-components'
import theme from 'src/theme';

interface IMapIndicatorProps {
  form?: IFormData;
  map: IExtendedMap
  mapIndex: number
  partners: ISynastryPartnerData[]
  width: number
  top?: number
  left?: number
  currentMode: string
  onModeChanged(mode: CircleMode | CircleMode[]): void
}

export type TEyeButtonMode = 'open' | 'close';

export const EyeButton = ({
  mode = 'open',
  onChange,
  className
}: {
  mode?: TEyeButtonMode
  onChange?: (mode: TEyeButtonMode) => void;
  className?: string;
}) => {
  const [localMode, setLocalMode] = React.useState<TEyeButtonMode>(mode);

  React.useEffect(() => {
    setLocalMode(mode);
  }, [mode])
  
  const onChangeMode = () => {
    const mode = localMode === 'open' ? 'close' : 'open';
    setLocalMode(mode);
    onChange?.(mode);
  }
  return (
    <div className={className} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', cursor: 'pointer' }} onClick={onChangeMode}>
      { localMode === 'open' ? <EyeIcon/> : <EyeCrossedIcon/>}
    </div>
  );
}

export default observer(function MapIndicator(props: IMapIndicatorProps) {
  const { t } = useTranslation();
  const localCurrentMode = props.currentMode as CircleMode;
  const partnersOptions = props.partners?.map((partner, index) => ({ value: `compatibility${index + 1}`, label: partner.name })) || []; 
  const [firstNatal, setFirstNatal] = useState(false);
  
  const isDoubleMap = 
    isCompatibility(localCurrentMode as CircleMode) 
    || prognosticModes.includes(localCurrentMode as string);

  const isPartnerMap = isPartner(localCurrentMode);

  
  React.useEffect(() => {
    if(!isCompatibility(localCurrentMode)) {
      setFirstNatal(false);
      store.instruments.setMapIndicatorPrognosticsExt(true, localCurrentMode as PrognosticsMode);
      if (localCurrentMode === 'directions') {
        store.instruments.setMapIndicatorPrognosticsNatal(true, localCurrentMode as PrognosticsMode);
      }
    }
  }, [props.mapIndex, localCurrentMode]);

  const singleName = React.useMemo((): string => {
    let result = t('astro.natal');
    if (isDoubleMap) return result;
    
    if (isRelocation(localCurrentMode)) {
      //@ts-ignore
      const placeName = (props.form?.relocation as any)?.[localCurrentMode].place.name.split(',')[0];
      result = `${t('astro.relocation.localMap')} - ${placeName}`
    
    } else if('horar' === localCurrentMode) {
      result = t(`astro.horar`)
    }

    return result; 
  }, [localCurrentMode]);
  
  // console.log(`current mode - ${localCurrentMode} isDouble: ${isDoubleMap} singleName: ${singleName}`)

  const extName = React.useMemo(() => {
    let result = 'extName';
    
    if (isCompatibility(localCurrentMode)) {
      return partnersOptions.find(option => option.value === localCurrentMode)?.label || result
    } else if(isPartner(localCurrentMode)) {
      return props.map.name || `partner${props.mapIndex + 1}`
    } else if (isPrognostics(localCurrentMode)) {
      return t(progressionsTitle[localCurrentMode]);
    }

    return result;
  }, [localCurrentMode]);

  const EyeModeForMapMode = React.useMemo((): TEyeButtonMode => {
    const storeValue = store.instruments.mapIndicatorCurrent[isPrognostics(localCurrentMode) ? 'prognosticsNatal' : 'partnerNatal'];
    // console.log(`EyeModeForMapMode - mode: ${localCurrentMode} value: ${(storeValue as any)[localCurrentMode]}`)
    return ( (storeValue as any)[localCurrentMode] ?? true ) ? 'open' : 'close';
  }, [
    store.instruments.mapIndicatorCurrent.prognosticsNatal,
    store.instruments.mapIndicatorCurrent.partnerNatal,
    props.mapIndex,
    localCurrentMode
  ]);
  
  const reorder = () => {
    setFirstNatal(firstNatal => !firstNatal);
    store.instruments.setMapCompatibilityReversed(!firstNatal);
  }
  
  const onChangeExtVisible = () => (eyeMode: TEyeButtonMode) => {
    // console.log(`onChangeExtEye - mode: ${eyeMode}`, )
    if (isPrognostics(localCurrentMode)) {
      store.instruments.setMapIndicatorPrognosticsExt(Boolean(eyeMode === 'open'), localCurrentMode as PrognosticsMode);
    } else if (isPartner(localCurrentMode)) {
      store.instruments.setMapIndicatorPartnerExt(localCurrentMode as SynastryMode, Boolean(eyeMode === 'open'), props.mapIndex);
    }
  }
  
  const onChangeNatalVisible = (eyeMode: TEyeButtonMode) => {
    // console.log(`onChangeNatalEye - localCurrentMode: ${localCurrentMode} mode: ${eyeMode}`,);
    if (isPrognostics(localCurrentMode)) {
      store.instruments.setMapIndicatorPrognosticsNatal(Boolean(eyeMode === 'open'), localCurrentMode as PrognosticsMode);
    } 
    else if(isPartner(localCurrentMode)) {
      store.instruments.setMapIndicatorPartnerNatal(localCurrentMode as SynastryMode, Boolean(eyeMode === 'open'), props.mapIndex);
    }
  }

  React.useEffect(() => {
    if (isPartnerMap) {
        onChangeNatalVisible('close');
    }
      }, [props.currentMode])
   
	return (
    <IndicatorContainer width={props.width}>
      
      {isCompatibility(localCurrentMode) && <SwapButton onClick={reorder}><SwapIcon /></SwapButton>}
      
      <SwitchWrapper firstNatal={firstNatal}>
        
        { (isDoubleMap || isPartnerMap) &&
            <ExtContainer className='ext-container'>
              {
                isCompatibility(localCurrentMode) && partnersOptions.length > 1
                  ? <CustomInput
                      value={extName}
                      options={partnersOptions}
                      onChange={(mode) => props.onModeChanged([mode])}
                      theme={theme.current}
                    />
                : <Tag theme={theme.current}><span className='tag-name'>{extName}</span>
                  {/* { isDoubleMap && localCurrentMode !== 'directions' &&
                      <div className='tag-button'><EyeButton mode='open' onChange={onChangeExtVisible()} /></div>
                    } */}
                  </Tag>
              }
            </ExtContainer>
        }
        { !isPartnerMap &&
          <Tag className='natal-container' theme={theme.current} mode={EyeModeForMapMode}><span className='tag-name'>{singleName}</span>
          {/* если только двойная карта и это не ! совместимость и не дирекции */}
          { isDoubleMap && !isCompatibility(localCurrentMode) && localCurrentMode !== 'directions' &&
              <div className='tag-button'><EyeButton mode={EyeModeForMapMode} onChange={onChangeNatalVisible} /></div>
          }
        </Tag>
        }
      
      </SwitchWrapper>

		</IndicatorContainer>
	)
})

const IndicatorContainer = styled.div<{ width?: number }>`
	display: flex;
	align-items: center;
  justify-content: center;
  flex-direction: row;
  width: ${(p) => (p.width ? `${p.width}px` : '5rem')};
`

const SwapButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  margin-right: 0.5rem;
  cursor: pointer;
  
  svg {
		width: 1.5rem;
		height: 1.5rem;
	}
`;

const ExtContainer = styled.div`
  display: flex;
`

const SwitchWrapper = styled.div<{ firstNatal: boolean }>`
  display: flex;
  flex-direction: row;

  & .ext-container {
    margin-left: 0;
  }

  & .natal-container {
    margin-left: 0.5rem;
  }

  ${p => p.firstNatal && css`
      flex-direction: row-reverse;

      & .ext-container {
        margin-left: 0.5rem; 
      }

      & .natal-container {
        margin-left: 0;
      }
  `}
  /* gap: 0.5rem; */
`;

const CustomInput = styled(Input)<{theme: string}>`

  background-color: var(--input-background-highlighted) !important;
  border-radius: var(--ui-border-radius-medium);
  border:${p => p.theme === 'light' ? 'var(--ui-input-border-size) solid var(--ui-input-border-color)' : 'none' };

  input {
    padding: 0.4rem 1rem 0.4rem 2rem;
    max-width: 10rem  !important;
    border: none;
    
    white-space: nowrap;
    overflow: hidden  !important;
    text-overflow: ellipsis !important;

  }

  .select-chevron-icon {
    right: initial;
    left: calc(var(--ui-input-mode-icon-right) - 0.2rem);
  }

  .ui-dropdown {
    .option {
      min-height: 2rem;
    }
  }
  

  /* input:-webkit-autofill:active {
    -webkit-text-fill-color: var(--text-primary) !important;
    box-shadow: none;
    transition: none;
  } */
`

const Tag = styled.div<{theme: string, mode?: string}>`
  display: flex;
  position: relative;
  min-height: 1.5rem;
  align-items: center;
  text-align: center;
  font-size:  0.875rem;
  padding: 0 0 0 0.75rem;
  color: var(--input-color);
  background-color: var(--input-background-highlighted);
  border-radius: 4px;
  border:${p => p.theme === 'light' ? 'var(--ui-input-border-size) solid var(--ui-input-border-color)' : 'none' };
  opacity:${p => p.mode === 'close' ? 0.5 : 1};
  max-width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & .tag-name {
    margin-right: 0.75rem;
  }

  & .tag-button {
    position: relative;
    flex: 1;
    min-width: 2rem;
    height: 100%;

    & svg {
      width: 1rem;
    }
  }
`
