import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import astro from 'src/astro';

import { ReactMemo } from 'src/hooks';

import { IWidgetData } from './Widget';
import { useTranslation } from 'src/i18n/useTranslation';
import { getModeMetaData } from '../utils';
import { IMap, isPrognostics, profileMode } from 'src/libs';
import TabPanel, { ITab } from 'src/components/TabPanel';
import { progressionsTitle } from '../utils';


const min = -100;
const max = 100;
const step = 5;

const info: {
  [key: string]: {
    tooltip: string;
  };
} = {
  natal: {
    tooltip: "astro.natal"
    // icon: <NatalIcon />,
  },
  synastry: {
    tooltip: "astro.synastry"
    // icon: <SynastryIcon />,
  },
  prognostics: {
    tooltip: "astro.prognostics"
    // icon: <PrognosticsIcon />,
  },

  compatibility: {
    tooltip: "astro.compatibility"
    // icon: <CompatibilityIcon />,
  },
  partner1: {
    tooltip: "astro.partner1"
    // icon: <Partner1Icon />,
  },
  partner2: {
    tooltip: "astro.partner2"
    // icon: <Partner2Icon />,
  },

  directions: {
    tooltip: "astro.directions"
    // icon: <DirectionsIcon />,
  },
  solars: {
    tooltip: "astro.solars"
    // icon: <SolarsIcon />,
  },
  transits: {
    tooltip: "astro.transits"
    // icon: <TransitsIcon />,
  },
  prog_prog: {
    tooltip: "astro.progProg"
    // icon: <ProgProgIcon />,
  },
  prog_natal: {
    tooltip: "astro.progNatal"
    // icon: <ProgNatalIcon />,
  },
  // RU
  relocation_natal: {
    tooltip: "astro.natal"
    // icon: <ProgNatalIcon />,
  },
  relocation1: {
    tooltip: "chronos.app.settings.relocation"
    // icon: <ProgNatalIcon />,
  },
  relocation2: {
    tooltip: "chronos.app.settings.relocation"
    // icon: <ProgNatalIcon />,
  },
  relocation3: {
    tooltip: "chronos.app.settings.relocation"
    // icon: <ProgNatalIcon />,
  },
  relocation4: {
    tooltip: "chronos.app.settings.relocation"
    // icon: <ProgNatalIcon />,
  },
  relocation5: {
    tooltip: "chronos.app.settings.relocation"
    // icon: <ProgNatalIcon />,
  },
  relocation6: {
    tooltip: "chronos.app.settings.relocation"
    // icon: <ProgNatalIcon />,
  },
  relocation7: {
    tooltip: "chronos.app.settings.relocation"
    // icon: <ProgNatalIcon />,
  },
  relocation8: {
    tooltip: "chronos.app.settings.relocation"
    // icon: <ProgNatalIcon />,
  },
  relocation9: {
    tooltip: "chronos.app.settings.relocation"
    // icon: <ProgNatalIcon />,
  },
};

export function OrbiseCorrectorTitle(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
}) {
  const { t } = useTranslation();
  const text = useMemo(() => {
    if(props.data.maps.length > 1 && isPrognostics(props.data.maps[0].mode)) {
      return t("astro.prognostics");
    }
    return props.data.maps?.map((map: IMap, idx: number) => {
      return t(getModeMetaData(map.mode).title ?? '')
    }
  )}, [props.data.maps]);

  return <OCT>
    <span />
    <span>{text}</span>
  </OCT>;
}

const OCT = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  & :first-child {
    height: 60%;
    margin: 0 0.75em;
    margin-top: 0.25em;
    border-right: 2px solid var(--element-neutral);
  }

  & :last-child {
    color: var(--text-secondary);
    font-size: 0.9em;
  }
`;

export default ReactMemo(function OrbiseCorrector(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
}) {
  const { t } = useTranslation();

  const singleValue = (astro.settings.maps as any)[profileMode(props.data.maps[0].mode)].orbiseCorrector || 0;
  const singlePercent = (singleValue + max) / (max + max) * 100;

  const handleChange = (e: any, mapIndex?: number) => {
    if (!props.data.form.access.isPersonal) return;
    props.onChanged('orbise-corrector', { value: +e.target.value, mapIndex });
  };

  const Tabs = React.useMemo(() => {
    
    let _tabs: ITab[] = [];
    
    props.data.maps.map((map: IMap, idx: number) => {
      const value = (astro.settings.maps as any)[profileMode(map.mode)].orbiseCorrector || 0;
      const percent = (value + max) / (max + max) * 100;

      const tab: ITab = {
        id: map.mode,
        title: t(getModeMetaData(map.mode).title ?? ''),
        component: <Container disabled={!props.data.form.access.isPersonal}>
          <Input
            type="range"
            min={min}
            max={max}
            step={step}
            value={value}
            percent={percent}
            onChange={(evt: any) => handleChange(evt, idx)}
          />

          <Indicator left={50}>{value}%</Indicator>
        </Container>
      }
      _tabs.push(tab);
    })

    return _tabs;

  }, [props.data.maps]);

  return (
    props.data.maps.length === 1
    ? <Container disabled={!props.data.form.access.isPersonal}>
        <Input
          type="range"
          min={min}
          max={max}
          step={step}
          value={singleValue}
          percent={singlePercent}
          onChange={(evt: any) => handleChange(evt, 0)}
        />

        <Indicator left={50}>{singleValue}%</Indicator>
      </Container>
    : <TabPanel tabs={Tabs} selected={props.data.maps[0].mode}></TabPanel>
  );
});

const Container = styled.div<{ disabled: boolean }>`
  position: relative;
  color: var(--text-third);
  font-size: 0.75rem;
  padding-bottom: 0.875rem;

  &::before {
    content: '${min}%';
    height: 85%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0.75rem;
    text-align: left;
    pointer-events: none;
  }

  &::after {
    content: '${max}%';
    height: 85%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0.5rem;
    text-align: right;
    pointer-events: none;
  }

  ${props => props.disabled && css`
    opacity: 0.75;
  `}
`;

const Input = styled.input<{ percent: number }>`
  -webkit-appearance: none;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  border-radius: 6px;
  height: 3.5rem;
  border: 1px solid var(--input-border);
  background-color: var(--input-background);
  outline: none;
  background-image: -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${props => props.percent}%, var(--bg-700)), color-stop(${props => props.percent}%, var(--layer-color)));

  &::-webkit-slider-thumb {
    margin-left: ${props => -0.75 + props.percent / 100 * 1.5}rem;
    -webkit-appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-size: cover;
    cursor: pointer;
    background-image: var(--orbise-thumb-icon);
  }

  &::-moz-range-track {
    width: 100%;
    border-radius: 6px;
    height: 3.5rem;
    border: 1px solid var(--input-border);
    background-color: var(--input-background);
    outline: none;
  }
  &::-moz-range-thumb {
    -webkit-appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    background-size: cover;
    cursor: pointer;
    background-image: var(--orbise-thumb-icon);
  }
`;

const Indicator = styled.div<{ left: number }>`
  position: absolute;
  display: flex;
  left: calc(${props => props.left}% - 1.35rem);
  top: 3.1rem;
  width: 3rem;
  height: 1.25rem;
  align-items: center;
  justify-content: center;
  background-color: var(--accent-blue);
  border-radius: 29px;
  color: var(--colors-white);
`;
