// Do not use src/libs because of rectification worker import problem
import { Astro, CircleMode } from '@chronos/astro-lib';
import wasm from './wasm';

export const CircleModes: CircleMode[] = [
  'natal',
  'compatibility',
  'transits', 'solars',
  'directions',
  'prog_prog',
  'prog_natal',
  'horar', 'soul' ,
  'relocation_natal',
  'relocation1',
  'relocation2',
  'relocation3',
  'relocation4',
  'relocation5',
  'relocation6',
  'relocation7',
  'relocation8',
  'relocation9',
  'syn_natal',
  'partner1',
  'partner2',
  'partner3',
  'partner4',
  'partner5',
  'partner6',
  'partner7',
  'partner8',
  'partner9',
  'partner10',
  'compatibility1',
  'compatibility2',
  'compatibility3',
  'compatibility4',
  'compatibility5',
  'compatibility6',
  'compatibility7',
  'compatibility8',
  'compatibility9',
  'compatibility10'

];

export default new Astro(wasm);
