import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon, DateTimeInput, Button, fillNumber, CircleMode, EditFormType } from 'src/libs';

import { clickOutsideHook, useDeviceDetect, useKeyHook } from 'src/hooks';


import TimeLine, { TimeLineMode } from './TimeLine';
import TimeLineTablet from './TimeLineTablet';
import { checkGenChanged, nowLocalISOString } from 'src/utils';
import api, { IBaseData, IFormData, parseISOString } from 'src/api';
import { goToMap } from "../../../../router";
import { cloneDeep } from 'lodash';
import Notify from 'src/ui/Wrappers/Notify';
import { useTranslation } from 'src/i18n/useTranslation';

import store from 'src/store';

export type TimeControlType = null | 'base' | 'ext';

interface TimeControlProps {
  type: TimeControlType;
  onChange(type: TimeControlType, dt: string, clearGen?: boolean): void;
  data: IBaseData;
  setShowTimeLine(mode: TimeLineMode): void;
  showTimeLine: TimeLineMode;
  icon?: any;
  useHotkeys?: boolean;
  isLocked?: boolean;
  onNotify(): void;
  autoPrediction: boolean;
  form: IFormData;
  onSubmit: (form: IFormData) => void;
  mode: EditFormType;
  modes: CircleMode[];
  limitedAccessAction?: () => void;
}

interface ISwitcherMode {
  [key: string]: TimeLineMode;
}

export const switcherMode: ISwitcherMode = {
  'null': 'day',
  'day': 'month',
  'month': 'year',
  'year': 'hour',
  'hour': 'minute',
  'minute': 'second',
  'second': 'day'
};

interface TimeControlState {
  editValue: string;
  value: string;
  isEdit: boolean;
}

const dateModes: TimeLineMode[] = ['day', 'month', 'year'];
const timeModes: TimeLineMode[] = ['hour', 'minute', 'second'];

export default function TimeControl(props: TimeControlProps) {
  const ref = useRef(null);
  const clicks = useRef<number[]>([]);
  const { t } = useTranslation();
  const { isTablet } = useDeviceDetect();
  const { isLimitedAccess } = store.settings.user;

  const [state, setState] = useState<TimeControlState>({
    editValue: '',
    value: props.data.dt,
    isEdit: false,
  });

  const [notification, setNotification] = useState(false);

  const isNatal = React.useMemo(() => {
    return props.mode === 'natal'
  }, [props.mode]);

  useEffect(() => {
    setState(() => ({
      ...state,
      value: props.data.dt
    }));
  }, [props.data]);

  clickOutsideHook(ref, () => {
    onChange(state.editValue);
  }, [state.editValue]);

  useKeyHook(
    ['AltLeft+KeyD'],
    {
      onKeyDown(key) {
        if (isLimitedAccess) return;
        
        if (key === 'AltLeft+KeyD' && props.useHotkeys && !props.isLocked) onChange(nowLocalISOString());
      }
    },
    [state, props],
    {
      execute: !!props.useHotkeys,
      repeat: false
    }
  );

  const onKeyDown = (key: string) => {
    if (!state.isEdit) return;

    if (key === 'Escape') setState(state => ({
      ...state,
      isEdit: false
    }));

    if (['Enter', 'NumpadEnter'].includes(key)) onChange(state.editValue);
  };

  useKeyHook(
    ['Escape', 'Enter', 'NumpadEnter'],
    { onKeyDown },
    [state],
    {
      execute: true,
      ignoreActiveInput: true,
      stopPropagation: false
    }
  );

  const onNotify = () => {
    // debugger
    if (props.isLocked && !isLimitedAccess) {
      props.onNotify();
    } else if (isLimitedAccess) {
      props.limitedAccessAction?.();
    }    
  }

  const onClick = (mode: TimeLineMode) => (ev: any) => {
    if (props.isLocked) return;

    clicks.current.push(setTimeout(() => {
      onNotify();

      if (props.showTimeLine === mode) return onHide();

      props.setShowTimeLine(mode);
    }, 250) as unknown as number);
  };

  const onDblClick = (mode: TimeLineMode) => (ev: any) => {
    ev && ev.stopPropagation();

    if (props.isLocked) return;

    clicks.current.forEach(t => clearTimeout(t));
    clicks.current = [];

    setState(() => ({
      ...state,
      editValue: state.value,
      isEdit: true
    }));

    props.setShowTimeLine(mode);
  };

  const onHide = () => {
    setState(() => ({
      ...state,
      isEdit: false
    }));
    props.setShowTimeLine(null);
  };


  const blockItem = (tmode: TimeLineMode, index: number) => {
    const values = parseISOString(state.value);
    return <BlockItem
      isActive={props.showTimeLine === tmode}
      onClick={onClick(tmode)}
      onDoubleClick={onDblClick(tmode)}
      isYear={tmode === 'year'}
    >
      {fillNumber(values[index])}
    </BlockItem>
};

  const openInTemporaryMap = async (dateTime: string) => {
    setNotification(false)
    onHide()
    const dfltForm = await api.form(-1);
    if (!dfltForm) { return }

    const form = { ...props.form };

    form.natal.dt = dateTime;

    delete form.rectification;

    await api.updateForm({
      ...form,
      name: "chronos.app.tmpMap",
      isTemporary: true,
      id: dfltForm.id
    });

    goToMap(-1, props.modes);
  }

  const onChange = (value: string) => {
    const {
      gen,
      natal: { dt, place: _place }
    } = props.form;
    const isGenChanged = checkGenChanged(gen, value, dt, _place, _place);
    if (isNatal && isGenChanged) {
      setNotification(true)
    } else {
      setState(() => ({
        ...state,
        value,
        isEdit: false
      }));
      props.onChange(props.type, value);
    }
  };

  const onTimeLineChange = (value: string, clearGen?: boolean) => {
    setState(() => ({
      ...state,
      value,
      isEdit: false
    }));
    props.onChange(props.type, value, clearGen);
  }

  const onChange2 = (value: string) => {
    setState(state => ({
      ...state,
      editValue: value
    }));
  };

  const changeData = () => {
    setNotification(false)
    setState(() => ({
      ...state,
      isEdit: false
    }));

    props.onChange(props.type, state.editValue, true);

    clicks.current.push(setTimeout(() => {
      props.setShowTimeLine(props.showTimeLine);
    }, 250) as unknown as number);
  }

  const safeData = (data: string) => {
    const _form = cloneDeep(props.form)

    props.onSubmit({
      ..._form,
      [props.mode]: {
        ..._form[props.mode],
        dt: data
      },
    });
  };

  const TimeLineComponent = isTablet ? TimeLineTablet : TimeLine;

  return (
    <>
      <Container disabled={props.isLocked ?? false} onClick={onNotify}>
        {(!state.isEdit || !props.showTimeLine) && <TimeLineComponent
          mode={props.showTimeLine}
          value={state.value}
          onChange={onTimeLineChange}
          onHide={onHide}
          form={props.form}
          isNatal={isNatal}
          openInTemporaryMap={openInTemporaryMap}
        />}

        {props.icon && <IconWrapper><Icon icon={props.icon}/></IconWrapper>}

        {state.isEdit ?
          <Section ref={ref}>
            <StyledDateTimeInput
              type='date'
              value={state.editValue}
              onChange={onChange2}
              autoFocus={dateModes.includes(props.showTimeLine)}
              isTimeControl={true}
              isDate={true}
              selectBeginning={true}
              utcMode
            />

            <StyledDateTimeInput
              type='time'
              value={state.editValue}
              onChange={onChange2}
              autoFocus={timeModes.includes(props.showTimeLine)}
              isTimeControl={true}
              selectBeginning={true}
              utcMode
            />
          </Section> :
          <>
            <Block>
              {blockItem('day', 2)}
              &nbsp;&middot;&nbsp;
              {blockItem('month', 1)}
              &nbsp;&middot;&nbsp;
              {blockItem('year', 0)}
            </Block>

            <Block>
              {blockItem('hour', 3)}
              &nbsp;:&nbsp;
              {blockItem('minute', 4)}
              &nbsp;:&nbsp;
              {blockItem('second', 5)}
            </Block>
          </>
        }
      </Container>

      {notification && <Notify
        type={'warning'}
        title={t("chronos.app.timeControl.buildingsWillBurn")}
        content={t("chronos.app.timeControl.ifUoyChangeDataWarning")}
        buttons={
          <>
            <Button
              style={{ background: `var(--colors-blue)` }}
              size="small"
              onClick={() => {
                openInTemporaryMap(state.editValue)
              }}>
              {t("chronos.app.instruments.openInTmpMap")}
            </Button>
            <Button
              size="small"
              color="transparent"
              style={{background: 'transparent', border: 'none', marginLeft: '1rem'}}
              onClick={changeData}
            >
              {t("chronos.auth.confirmationEmail.changeData")}
            </Button>
          </>
        }

        onClose={() => {
          setNotification(false)
          onHide()
        }}
        style={{top: '-750%', left: '38%'}}
      />}
    </>
  );
}

const Section = styled.section`
  & > :last-child {
    input {
      margin-right: 0;
    }
  }
`;

const StyledDateTimeInput = styled(DateTimeInput)<{ isDate?: boolean }>`
  margin-right: 0.5em;
  height: 2.375rem;
  width: 7.5rem;
  padding: 0.77rem 0.5rem !important;
  text-align: center;
`;

const Container = styled.div<{ disabled: boolean }>`
  display: flex;

  & > :last-child {
    margin-right: 0;
  }

  ${props => props.disabled && css`
    opacity: 0.75;
  `}

  & > section {
    display: flex;
  }
`;

const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--element-neutral);
  min-height: 2.375rem;
  width: 7.5rem;
  border-radius: 0.25rem;
  color: var(--text-secondary);

  margin-right: 0.5rem;

  backdrop-filter: brightness(0.9) blur(3px);
`;

const BlockItem = styled.span<{ isActive: boolean; isYear?: boolean }>`
    //min-width: ${props => props.isYear ? '2.75em' : '2em'};

  user-select: none;
  cursor: pointer;
  text-align: center;

  ${props => props.isActive && css`
    color: var(--accent-blue);
  `}
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;

  svg {
    width: 1.125rem;
    fill: var(--icon-secondary);
  }
`;
