import React from 'react';
import ReactDOM from 'react-dom';

import {
  IAspect,
  AspectType,
  majorAspects,
  IStrongAspect,
  getObjectName,
  isNotTransits,
  checkAspect, IObject, ObjectType, PrognosticsMode, getSign, isPrognostics
} from 'src/libs';

import { aspectsIcons } from 'src/icons';

import { IStrongs } from 'src/pages/Instruments/Widgets/Widget';
import { CirclePartProps } from './index';
import { hideMapInfoPopup, showMapInfoPopup } from 'src/pages/Instruments/Maps';
import astro from 'src/astro';
import { degToString, distance } from 'src/api';
import store from 'src/store';
import { t } from 'i18next';
import { getArrangeObjects, fortuneLon, IArrangedObject } from './Objects';
import { isCompatibility } from 'src/pages/Instruments/utils';

function isAccurateAspect(a: {
  orbise: number;
  realOrbise: number;
}) {
  const orbises: {
    [key: number]: number;
  } = {
    10: 3,
    9: 3,
    7: 2,
    5: 2
  };

  return a.realOrbise <= (orbises[a.orbise] || a.orbise);
}

export enum MinorAspectsModes {
  On,
  Off,
  School
}

interface AspectsProps extends CirclePartProps {
  aspects: IAspect[];
  strongs: IStrongs;
  objects: IObject[];
  houses: number[];
  horarSigns: number[];
  minorAspectsMode?: MinorAspectsModes;
}

export default class Aspects extends React.Component<AspectsProps> {
  private readonly _container = React.createRef<SVGGElement>(); 

  shouldComponentUpdate(newProps: AspectsProps): boolean {
    this.update(newProps);
    return Boolean(newProps.isEditor) || newProps.personalization.name !== this.props.personalization.name;
  }

  componentDidMount(): void {
    this.update(this.props);
  }

  update = (newProps: AspectsProps) => {
    if (!this._container.current) {
      return;
    }

    const { personalization } = newProps;
    const { computedRadius } = newProps;

    const iconSize = newProps.mode === 'horar' ? newProps.radius * personalization.circle.aspects.icon * 2 : newProps.radius * personalization.circle.aspects.icon;
    const radius = newProps.mode === 'horar' ? newProps.radius : newProps.radius * (newProps.hasExt ? computedRadius.internalExt : computedRadius.internal);
    let _aspects = newProps.aspects;   

    let aObjects: IArrangedObject[] = [];

    const { showAspects } = store.settings.profile.ui.instruments.cardSettingsForms.horar;

    if (newProps.mode === 'horar') {
      aObjects = getArrangeObjects(
        newProps.objects.map((o, id) => ({ ...o, id }))
        .filter((obj) => (obj.id !== ObjectType.Chiron || !isNotTransits(newProps.mode)) && obj.id !== ObjectType.Selena),
        newProps.houses,
        +(newProps.radius * personalization.circle.objects.iconSize / 4).toFixed(2),
        newProps.horarSigns,
        3,
        []
      );
      
      _aspects = _aspects.filter(aspect => {
        return (
          (
            !astro.getOrbise('natal', aspect.obj1, aspect.type)?.disabled &&
            !astro.getOrbise('natal', aspect.obj2, aspect.type)?.disabled
          ) ||
          newProps.highlights?.includes(aspect.id)
        ) &&
        majorAspects.includes(aspect.type) &&
        aspect.obj2 <= ObjectType.Saturn &&
        aspect.obj1 <= ObjectType.Saturn;
      });
    } else {
        _aspects = _aspects.filter(aspect => { 

        const isVisible = (
          !astro.getOrbise(newProps.mode, aspect.obj1, aspect.type)?.disabled &&
          !astro.getOrbise(newProps.mode, aspect.obj2, aspect.type)?.disabled ||
          newProps.highlights?.includes(aspect.id)
          );
        if (newProps.minorAspectsMode === MinorAspectsModes.School || majorAspects.includes(aspect.type)) {
          return isVisible;
        }

        return newProps.minorAspectsMode === MinorAspectsModes.On;
      });
    }
    
    if (
      newProps.mode === 'transits' &&
      newProps.objects.length
    ) {
      _aspects = _aspects.filter(aspect => {
        return newProps.highlights?.includes(aspect.id) ||
        (store.activeAstroProfile?.hideTransitAspects ? (aspect.obj2 > ObjectType.Mars || newProps.objects[aspect.obj2]?.speed <= 0) : true)
        && aspect.obj2 !== ObjectType.Chiron
        && aspect.obj2 !== ObjectType.NorthNode
        && aspect.obj2 !== ObjectType.SouthNode
        && aspect.obj1 !== ObjectType.Chiron
      }
      );
    }

    // включен/отключен натал в двойной карте прогностики
    const indicatorPrognosticsNatal = (store.instruments.mapIndicatorCurrent.prognosticsNatal as any)[newProps.mode as PrognosticsMode];
    const nodes = [ObjectType.NorthNode, ObjectType.SouthNode];
    // если натал отключен, то на карте prog_natal скрываем аспекты с домами
    // скрываем оппозиции узлов
    if (!indicatorPrognosticsNatal && ['solars', 'prog_natal', 'transits'].includes(newProps.mode)) {
      _aspects = _aspects.filter(aspect => {
        return newProps.highlights?.includes(aspect.id) || !(newProps.mode === 'prog_natal' && (aspect.obj1 >= ObjectType.House1 || aspect.obj2 >= ObjectType.House1))
          && !(
                aspect.type === AspectType.Opposition 
                && nodes.includes(aspect.obj1) && nodes.includes(aspect.obj2)

              )
      })
    }

    const container = this._container.current;

    const w3Url = 'http://www.w3.org/2000/svg';

    while (container.childElementCount < _aspects.length) {
      const group = document.createElementNS(w3Url, 'g');

      group.style.cursor = 'pointer';

      const line = document.createElementNS(w3Url, 'line');
      group.append(line);

      const lineBack = document.createElementNS(w3Url, 'line');
      group.append(lineBack);

      const back = document.createElementNS(w3Url, 'circle');
      back.setAttribute('r', (iconSize / 2).toString());
      back.setAttribute('fill', 'transparent');
      group.append(back);

      const icon = document.createElementNS(w3Url, 'svg');
      icon.setAttribute('viewBox', '0 0 24 24');
      icon.setAttribute('width', '24');
      icon.setAttribute('height', '24');

      group.append(icon);

      container.append(group);
    }

    _aspects.forEach((aspect, ind) => {
      const noIcon =
        aspect.type === AspectType.Conjunction ||
        aspect.type === AspectType.Opposition;

      const lon1 = newProps.mode === 'horar' ? aObjects.find(obj => obj.id === aspect.obj1)!.lon : aspect.lon1;
      const lon2 = newProps.mode === 'horar' ? aObjects.find(obj => obj.id === aspect.obj2)!.lon : aspect.lon2;

      const p1 = newProps.point(radius, lon1);
      const p2 = newProps.point(radius, lon2);
      const pc = {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2
      };

      const gap = noIcon ? 0 : iconSize;
      const len = (distance(p1, p2) - gap) / 2;

      const isHighlighted = !newProps.highlights || newProps.highlights.includes(aspect.id);

      const opacity = isHighlighted ? 1 : 0.2;

      // const color = `rgba(var(--circle-aspects-${aspect.type}-rgb), ${opacity})`;
      const color = `rgba(var(--circle-aspects-${aspect.type}-rgb-${personalization.circle.colorThemes.aspects.active}), ${opacity})`;

      const strokeDasharray = majorAspects.includes(aspect.type) ? `${len} ${gap}` : '5, 5';

      const strokeMul = newProps.radius * (
        isHighlighted || newProps.strongs.aspects.find((a: IStrongAspect) =>
          a.type === aspect.type &&
          a.obj1 === aspect.obj1 &&
          a.obj2 === aspect.obj2 &&
          a.weight > 0
        )
          ?
          0.0015 :
          isAccurateAspect(aspect) ? 0.001 : 0.0005
      ) * personalization.circle.aspects.width;

      const group = container.childNodes[ind] as SVGGElement;

      group.onmouseenter = (ev: any) => {
        if (store.settings.user.isLimitedAccess) return false;
        
        if (newProps.mode !== 'solars' && !isCompatibility(newProps.mode)) {
          const diff = checkAspect(
            aspect.orbise,
            aspect.lon1,
            aspect.lon2,
            aspect.type,
            {
              ...astro.checkAspectOpts(showAspects && this.props.mode === 'horar' ? 'natal' : this.props.mode),
              isRounded: false
            }
          );
          showMapInfoPopup(ev.target, [`${aspect.type}°  ${t(getObjectName(aspect.obj1))}  ${(aspect?.diff || 0) < 0 ? '><' : '<>'}  ${t(getObjectName(aspect.obj2))}  ${degToString(diff.lon, { isInternational: false, showSeconds: true })} (${degToString(diff.diff, { isInternational: false, showSeconds: true })})`], ev);
        }

        newProps.onHover('aspect', aspect.id);
      };

      group.onmouseleave = () => {
        newProps.onHover('aspect', -1);
        hideMapInfoPopup();
      };

      group.onclick = () => newProps.onHelp('aspects', aspect.type);

      const line = group.childNodes[0] as SVGLineElement;

      const lineBack = group.childNodes[1] as SVGLineElement;
      
      line.setAttribute('x1', p1.x.toString());
      line.setAttribute('y1', p1.y.toString());
      line.setAttribute('x2', p2.x.toString());
      line.setAttribute('y2', p2.y.toString());

      lineBack.setAttribute('x1', p1.x.toString());
      lineBack.setAttribute('y1', p1.y.toString());
      lineBack.setAttribute('x2', p2.x.toString());
      lineBack.setAttribute('y2', p2.y.toString());

      if (aspect.type === 90 && aspect.obj1 === 5 && aspect.obj2 === 7) {
        group.classList.add('square');
      } else {
        group.classList.remove('square');
      }

      line.setAttribute('stroke', color);
      line.setAttribute('stroke-width', aspect.type === 0 ? (strokeMul * 5).toString() : strokeMul.toString());
      line.setAttribute('stroke-dasharray', strokeDasharray);
      line.setAttribute('fill', 'none');

      lineBack.setAttribute('stroke', 'transparent');
      lineBack.setAttribute('stroke-width', (strokeMul * 10).toString());
      lineBack.setAttribute('fill', 'none');
      // line.setAttribute("opacity", opacity.toString());

      line.style.display = 'block';

      lineBack.style.display = 'block';

      const back = group.childNodes[2] as SVGCircleElement;
      const icon = group.childNodes[3] as SVGSVGElement;
      icon.tabIndex =  -1;
      if (noIcon) {
        back.style.display = 'none';
        icon.style.display = 'none';
      } else {
        back.setAttribute('cx', pc.x.toString());
        back.setAttribute('cy', pc.y.toString());
        back.style.display = 'block';

        const Icon = aspectsIcons[aspect.type];

        icon.setAttribute('x', (pc.x - iconSize / 2).toString());
        icon.setAttribute('y', (pc.y - iconSize / 2).toString());

        ReactDOM.render(<Icon
          fill={color}
          stroke={color}
          strokeWidth={strokeMul}
          width={iconSize}
          height={iconSize}
          // opacity={opacity}
        />, icon);

        icon.style.display = 'block';
      }

      group.style.display = 'block';
    });

    for (let i = _aspects.length; i < container.childElementCount; i++) {
      (container.childNodes[i] as SVGGElement).style.display = 'none';
    }
  };

  render(): JSX.Element {
    return <g ref={this._container} />;
  }
}
