// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//                                                     DO NOT USE CYCLIC IMPORTS HERE
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// Do not use src/libs because of rectification worker import problem

// import 'regenerator-runtime/runtime';
import dayjs from 'dayjs';
import { Buffer } from 'buffer';
import { AstroCalculator, HousesSystem, IObject, ObjectType, hSysCodes, revJulDay } from '@chronos/astro-lib';


interface IModule {
  HEAPU8: number[];

  _malloc(size: number): number;
  _free(ptr: number): void;

  _calc_object(pl: number, jd: number, lonPtr: number, speedPtr: number): void;
  _calc_objects(jd: number, lonsPtr: number, speedsPtr: number): void;
  _calc_houses(jd: number, lat: number, lon: number, hSys: number, housesPtr: number): void;
  _calc_fixstar(namePtr: number, jd: number, lonPtr: number, speedPtr: number): void;
  _rise_object(namePtr: number, jd: number, obj: ObjectType, rsmi: number, lon: number, lat: number, height: number, resultPtr: number): void;
}

const f64 = Float64Array.BYTES_PER_ELEMENT;

export const SE_CALC_RISE = 1;
export const SE_CALC_SET = 2;
export const SE_NAME_CAPACITY = 41;

class WASM extends AstroCalculator {
  private readonly _module: IModule;

  constructor(module: IModule) {
    super();
    this._module = module;
  }

  riseObject(jd: number, obj: ObjectType, lat: number, lon: number, isNextRise?: boolean) {
    const name = '';

    return this.calc(ptr => {
      this.writeString(ptr + f64, name);

      this._module._rise_object(ptr + f64, jd - (isNextRise ? 0 : 1), obj, SE_CALC_RISE + 256 + 512, lon, lat, 0, ptr);

      return this.readNumber(ptr);
    }, f64 + SE_NAME_CAPACITY);
  }

  setObject(jd: number, obj: ObjectType, lat: number, lon: number) {
    const name = '';

    return this.calc(ptr => {
      this.writeString(ptr + f64, name);

      this._module._rise_object(ptr + f64, jd - 1, obj, SE_CALC_RISE, lon, lat, 0, ptr);

      const result = this.readNumber(ptr);

      // FIXME: revert to dt ?
      const decreaseJD = dayjs(revJulDay(result)).date() !== dayjs(revJulDay(jd)).date();

      this._module._rise_object(ptr + f64, jd - (decreaseJD ? 1 : 0), obj, SE_CALC_SET + 256 + 512, lon, lat, 0, ptr);

      return this.readNumber(ptr);
    }, f64 + SE_NAME_CAPACITY);
  }

  object(jd: number, obj: ObjectType) {
    return this.calc(ptr => {
      this._module._calc_object(obj, jd, ptr, ptr + f64);

      const buffer = this.makeBuffer(ptr, 2 * f64);

      return {
        lon: buffer.readDoubleLE(0),
        speed: buffer.readDoubleLE(f64)
      };
    }, 2 * f64);
  }

  // FIXME: implement objects (fast version)

  houses(jd: number, hSys: HousesSystem, lat: number, lon: number) {
    return this.calc(ptr => {
      this._module._calc_houses(jd, lat, lon, hSysCodes[hSys], ptr);
      return this.readHouses(this.makeBuffer(ptr, 12 * f64));
    }, 12 * f64);
  }

  star(jd: number, name: string): IObject {
    return this.calc(ptr => {
      this.writeString(ptr + 2 * f64, name);

      this._module._calc_fixstar(ptr + 2 * f64, jd, ptr, ptr + f64);

      const buffer = this.makeBuffer(ptr, 2 * f64);

      return {
        lon: buffer.readDoubleLE(0),
        speed: buffer.readDoubleLE(f64)
      };
    }, 2 * f64 + SE_NAME_CAPACITY);
  }

  private makeBuffer(ptr: number, size: number) {
    return Buffer.from(this._module.HEAPU8.slice(ptr, ptr + size));
  }

  private readNumber(ptr: number) {
    return this.makeBuffer(ptr, f64).readDoubleLE(0);
  }

  private writeString(ptr: number, v: string) {
    for (let i = 0; i < v.length; i++) {
      this._module.HEAPU8[ptr++] = v.charCodeAt(i);
    }
    this._module.HEAPU8[ptr] = 0;
  }

  private readHouses(buffer: Buffer, housesOffset = 0) {
    const houses: number[] = [];
    for (let i = 0; i < 12; i++) {
      houses.push(buffer.readDoubleLE(housesOffset + i * f64));
    }
    return houses;
  }

  private calc<T>(cb: (ptr: number) => T, size: number) {
    let ptr = 0;
    try {
      ptr = this._module._malloc(size);
      return cb(ptr);
    } finally {
      this._module._free(ptr);
    }
  }
}

export default (async () => new WASM(
  await fetch(`${process.env.REACT_APP_S3}/wasm/${process.env.REACT_APP_NODE_ENV === "production" ? "master" : "dev"}/astro.js?v=1`)
    .then(x => x.text())
    .then(x => new Function(`${x}; return Module`)()())
))();
