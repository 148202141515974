import React, { useEffect, useState } from 'react';
import { IWidgetsItem} from "../../api";
import {widgets} from "./Widgets";
import Attw from "../../components/AdvancedTooltipWrapper";
import {ReactMemo} from "../../hooks";
import styled, {css} from "styled-components";
import InstrumentButton from 'src/components/InstrumentButton';
import { useDeviceDetect } from 'src/hooks';
import Select from 'src/ui/Select';
import { CircleMode } from 'src/libs';

import store from 'src/store';

interface IWidgetsButtonsProps {
  widgets: IWidgetsItem[]
  isLocked: boolean
  modes: CircleMode[]
  onClick: (id: string) => void
  onReplace: (from: string, to: string) => void
  limitedAccess: boolean
  limitedAccessAction: () => void; 
}

export default ReactMemo(function WidgetsButtons(props: IWidgetsButtonsProps) {

  const { isLimitedAccess } = store.settings.user;

  const { isTablet } = useDeviceDetect();
  const [screenWidth, setScreenWidth] = useState(screen.width || window.innerWidth);

  const btnWidgetsAmounth = screenWidth < 960 ? 0 : 3;
  const btnWidgets = isTablet ? props.widgets.slice(props.widgets.length - btnWidgetsAmounth) : props.widgets;
  const selectWidgets = isTablet ? props.widgets.slice(0, props.widgets.length - btnWidgetsAmounth) : null;

  const onScreenSizeChanged = () => setScreenWidth(screen.width || window.innerWidth);
  
  useEffect(() => {
    window.addEventListener('resize', onScreenSizeChanged);

    return () => window.removeEventListener('resize', onScreenSizeChanged);
  }, []);

  const widgetsButtons = <>
    <Container isEmptyWidgets={selectWidgets?.length === 0}>
      { selectWidgets
          ? <Select
              isTop={true}
              isLeft={true}
              options={selectWidgets.map((w, i) => ({
                id: i,
                Icon: widgets[w.id].icon,
                title: widgets[w.id].title,
                rightIcon: () => null,
              }))}
              onSelect={(id) => {
                props.onClick(selectWidgets[id].id)
              }}
              hideKeyIcon
              hideSelected
              selected={props.widgets
                .map((w, i) => w.isActive ? i : -1)
                .filter(id => id >= 0)
              }
              closeOnClickOutside={true}
            />
          : null
      }
    </Container>
    <Container isEmptyWidgets={btnWidgets?.length === 0}>
      {
        btnWidgets.map((w, i, array) => {
          if (
            !widgets[w.id] ||
            props.isLocked && ['devplan', 'autoprediction', 'rectification'].includes(w.id)
          ) {
            return null;
          }

          const align = array.length - i <= 3 ? 'right' : 'left'
          //@ts-ignore
          const limitedAccess = isLimitedAccess && !w.showWithAccessLimit;
    
          return (
            <Attw
              text={widgets[w.id].title}
              align={align}
              key={`ttw_btn_wgt_${w.id}`}
            >
              <InstrumentButton
                id={w.id}
                key={`btn_wgt_${w.id}`}
                isActive={!!w.isActive}
                // modes={props.modes} // FIXME: !!!
                activeStyle="color"
                onClick={() => {
                  if (!limitedAccess) {
                    props.onClick(w.id)
                  } else {
                    props.limitedAccessAction?.()
                  }
                }}
                onReplace={props.onReplace}
                isLocked={props.isLocked}
                icon={widgets[w.id].icon}
                //@ts-ignore
                disabled={limitedAccess}
              />
            </Attw>
          )
        })
      }
    </Container>
  </>

  return widgetsButtons
})

const Container = styled.div<{isEmptyWidgets: boolean}>`
  margin: 0 0.5rem;
  
  & > * {
    margin-left: 0.5rem;
  }

  ${props => props.isEmptyWidgets && css`
  margin: 0;
  `}
`;


