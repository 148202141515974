import React from 'react';

import { houseNames, isNotTransits, IPoint, CircleMode } from 'src/libs';

import { CirclePartProps, Group } from './index';
import { hideMapInfoPopup, showMapInfoPopup } from 'src/pages/Instruments/Maps';
import { degToString } from 'src/api';
import store from 'src/store';

interface HousesProps extends CirclePartProps {
  houses: number[];
  isExt?: boolean;
  t: any;
}

function getStyle(props: HousesProps, id: number, opacity = '1', theme: number, width = 1) {
  const isMajorCusps = [0, 3, 6, 9].includes(id);
  const isSpec = (props.mode === 'natal') && isMajorCusps;
  const doubleMapWithOutNatal = props.doubleMapWithOutNatal && isMajorCusps;

  const color = props.mode === 'prog_prog' 
    ? `rgba(var(--circle-houses-alternative-rgb-${theme}), ${opacity})` 
    : (isSpec || doubleMapWithOutNatal) 
        ? `rgba(var(--circle-houses-special-rgb-${theme}), ${opacity})` 
        : props.isExt 
          ? `rgba(var(--circle-houses-alternative-rgb-${theme}), ${opacity})` 
          : (isNotTransits(props.mode) && !props.doubleMapWithOutNatal) 
            ? `rgba(var(--circle-houses-special-rgb-${theme}), ${opacity})` 
            :`rgba(var(--circle-houses-usual-rgb-${theme}), ${opacity})`;

  const strokeWidth = isSpec || props.mode === 'horar' && isMajorCusps ? 2 * width : width;

  return { isSpec, color, strokeWidth };
}

export default class Houses extends React.Component<HousesProps> {
  private readonly _container = React.createRef<SVGGElement>();

  private readonly _cusps: React.RefObject<SVGGElement>[] = [];

  constructor(props: HousesProps) {
    super(props);

    for (let i = 0; i < 12; i++) {
      this._cusps.push(React.createRef<SVGSVGElement>());
    }
  }

  componentDidMount(): void {
    this.update(this.props);
  }

  shouldComponentUpdate(newProps: HousesProps): boolean {
    this.update(newProps);
    return !!newProps.isEditor || newProps.personalization.name !== this.props.personalization.name;
  }

  update = (newProps: HousesProps) => {
    const { personalization } = newProps;
    const { computedRadius } = newProps;

    const asc = this._container.current!.firstChild as SVGPathElement;
    const mc = this._container.current!.lastChild as SVGCircleElement;

    if (newProps.houses.length === 0 && newProps.mode !== 'soul') {
      asc.style.display = 'none';
      mc.style.display = 'none';
      this._cusps.forEach(c => c.current!.style.display = 'none');
      return;
    }

    const housesRadius = newProps.radius * personalization.circle.radius.houses * personalization.circle.houses.length;
    const endRadius = newProps.radius * personalization.circle.radius.cusps * personalization.circle.houses.length;
    const outRadius = newProps.radius * personalization.circle.radius.external;

    const extRadius = newProps.radius * (
      newProps.hasExt ?

        newProps.isExt ?
          computedRadius.external :
          computedRadius.zodiacsInternalExt
        :
        computedRadius.zodiacsInternal
    );

    const getInternalRadius = (mode: CircleMode) => {
      switch (mode) {
        case 'horar': return personalization.circle.radius.internalHorar;
        case 'soul': return personalization.circle.radius.internalSoul || 0.1;
        default: return computedRadius.internal;
      }
    };

    const intRadius = newProps.radius * (
      newProps.hasExt ?

        newProps.isExt ?
          computedRadius.zodiacsExternalExt :
          computedRadius.internalExt
        :
        getInternalRadius(newProps.mode)
    );

    const updateLine = (l: SVGLineElement, p1: IPoint, p2: IPoint, stroke: string, strokeWidth: number) => {
      l.setAttribute('x1', p1.x.toString());
      l.setAttribute('y1', p1.y.toString());
      l.setAttribute('x2', p2.x.toString());
      l.setAttribute('y2', p2.y.toString());
      l.setAttribute('stroke', stroke);
      l.setAttribute('stroke-width', strokeWidth.toString());
    };

    for (let i = 0; i < 12; i++) {
      const lon = ['horar', 'soul'].includes(newProps.mode) ? (newProps.mode === 'soul' ? 0 : 180) + i * 30 : newProps.houses[i];

      const cusp = this._cusps[i].current as SVGGElement;

      const endPos = newProps.point(endRadius * (newProps.mode === 'horar' ? 0.95 : 1), lon);
      const outPos = newProps.point(outRadius, lon);
      const extPos = newProps.point(extRadius, lon);
      const intPos = newProps.point(intRadius, lon);

      const { x, y } = newProps.point(housesRadius * (['horar', 'soul'].includes(newProps.mode) ? i == 0 || i == 6 ? 1.025 : 0.975 : 1), lon);

      const opacity = !newProps.highlights || newProps.highlights.includes(i) ? '1' : '0.2';

      const style = getStyle(newProps, i, opacity, personalization.circle.colorThemes.houses.active, personalization.circle.houses.width);

      if (newProps.mode === 'horar' && [0, 3, 6, 9].includes(i)) {
        style.color = 'var(--text-primary)';
      }

      const house = cusp.lastChild as SVGSVGElement;

      house.onmouseenter = (ev: any) => {
        if (store.settings.user.isLimitedAccess) return false;
        // newProps.onHover(highlightId, i);
        if (newProps.mode === 'soul') {
          showMapInfoPopup(ev.target, [this.props.t("chronos.app.components.circle.year", {count: i * 7})], ev);
        } else {
          showMapInfoPopup(ev.target, [this.props.t("chronos.app.components.circle.house", { house: i + 1, deg: degToString(newProps.houses[i] % 30, { showSeconds: true, isInternational: false }) })], ev);
        }
      };

      house.onmouseleave = () => {
        // newProps.onHover(highlightId, -1);
        hideMapInfoPopup();
      };

      house.setAttribute('x', x.toString());
      house.setAttribute('y', y.toString());

      const htext = cusp.childNodes[0] as SVGTextElement;
      const hfirstChild = house.lastChild?.firstChild as SVGSVGElement;
      const hlastChild = house.lastChild?.lastChild as SVGSVGElement;

      if (newProps.mode === 'horar') {
        house.setAttribute('color', 'var(--text-secondary)');

        const name = (() => {
          switch (i) {
            case 0: return 'Asc ';
            case 3: return 'IC ';
            case 6: return 'Dsc ';
            case 9: return 'MC ';
            default: return '';
          }
        })();

        hfirstChild.textContent = `${name}${degToString(newProps.houses[i] % 30, { isInternational: false })}`;
        if (name) {
          hfirstChild.style.fontWeight = 'bold';
          hfirstChild.style.color = 'var(--text-primary)';
        }
        hlastChild.style.display = 'none';

        const { x, y } = newProps.point(intRadius - newProps.radius * 0.04, lon);

        htext.setAttribute('x', x.toString());
        htext.setAttribute('y', y.toString());

        htext.style.display = 'block';
        htext.textContent = (i + 1).toString();
      } else if (newProps.mode == 'soul') {
        htext.style.display = 'none';
        hfirstChild.textContent = (i * 7).toString();
        hlastChild.style.display = 'none';
      } else {
        htext.style.display = 'none';
        house.setAttribute('color', style.color);

        hfirstChild.textContent = houseNames[i];
        hfirstChild.style.fontWeight = 'normal';
        hfirstChild.style.color = style.color;
        hlastChild.style.display = 'block';
        hlastChild.textContent = ` ${Math.ceil(newProps.houses[i] % 30)}`;
      }

      updateLine(cusp.childNodes[1] as SVGLineElement, endPos, outPos, style.color, style.strokeWidth);
      updateLine(cusp.childNodes[2] as SVGLineElement, extPos, intPos, style.color, style.strokeWidth);

      cusp.style.display = 'block';
    }

    if (['horar', 'soul'].includes(newProps.mode)) {
      asc.style.display = 'none';
      mc.style.display = 'none';
      return;
    }

    const ascStyle = getStyle(newProps, 0, '1', personalization.circle.colorThemes.houses.active, personalization.circle.houses.width);

    asc.setAttribute('stroke', ascStyle.color);
    asc.setAttribute('stroke-width', ascStyle.strokeWidth.toString());

    const p = newProps.point(endRadius, newProps.houses[0]);

    const arrowPoint = (angle: number) => {
      const p = newProps.point(endRadius - newProps.radius * 0.02, newProps.houses[0] + 1.25 * angle);
      return `${p.x} ${p.y}`;
    };

    asc.setAttribute('d', `
      M ${arrowPoint(-1)}
      L ${p.x} ${p.y}
      L ${arrowPoint(1)}
    `);

    const mcStyle = getStyle(newProps, 9, '1', personalization.circle.colorThemes.houses.active, personalization.circle.houses.width);
    const r = newProps.radius * 0.02;
    const o = newProps.point(endRadius - r, newProps.houses[9]);
    mc.setAttribute('r', r.toString());
    mc.setAttribute('cx', o.x.toString());
    mc.setAttribute('cy', o.y.toString());
    mc.setAttribute('stroke', mcStyle.color);
    mc.setAttribute('stroke-width', mcStyle.strokeWidth.toString());

    asc.style.display = 'block';
    asc.style.strokeOpacity = !newProps.highlights || newProps.highlights.includes(0) ? '1' : '0.2';

    mc.style.display = 'block';
    mc.style.strokeOpacity = !newProps.highlights || newProps.highlights.includes(9) ? '1' : '0.2';
  };

  render(): JSX.Element {
    const { props } = this;
    const fontRadius = props.radius * 0.04;

    const pointSize = props.radius * props.personalization.circle.objects.pointSize;

    const houses: any[] = [];

    for (let id = 0; id < 12; id++) {
      const style = getStyle(this.props, id, '1', props.personalization.circle.colorThemes.houses.active, props.personalization.circle.houses.width);

      houses.push(
        <Group
          key={`${props.mode}_${id}`}
          ref={this._cusps[id]}
          onClick={() => props.onHelp('houses', id)}
          id={`house-group-${this.props.t(houseNames[id])}`}
        >
          <text
            style={{
              display: 'none',
              fontSize: fontRadius,
              fill: 'var(--text-third)',
              textAnchor: 'middle',
              alignmentBaseline: 'middle'
            }}
          />

          <line
            stroke={style.color}
            strokeWidth={style.strokeWidth}
          />

          <line
            stroke={style.color}
            strokeWidth={style.strokeWidth}
            strokeOpacity={0.5}
          />

          {/* <circle
            r={pointSize}
            stroke="currentColor"
            fill="var(--workspace-background)"
          /> */}

          <svg
            overflow="visible"
            color={style.color}
          >
            <circle
              r={fontRadius}
              fill="transparent"
            />

            <text
              // x={fontRadius * 0.5}
              style={{
                fontWeight: style.isSpec ? 'bold' : '700',
                fontSize: fontRadius,
                fill: 'currentColor',
                textAnchor: 'middle',
                alignmentBaseline: 'middle'
              }}
            >
              <tspan>{houseNames[id]}</tspan>

              <tspan
                style={{
                  fontWeight: style.isSpec ? 'bold' : 'normal',
                  fontSize: fontRadius * 0.7,
                  baselineShift: 'super'
                }}
              />
            </text>
          </svg>
        </Group>
      );
    }

    return (
      <g ref={this._container}>
        {/* asc */}
        <path fill="none" />

        {houses}

        {/* mc */}
        <circle fill="var(--workspace-background)" />
      </g>
    );
  }
}
