import { IFormData, PrognosticsMode, SynastryMode } from 'src/libs';
import { makeAutoObservable, observable, runInAction } from 'mobx';

export interface IPrognosticsMapsState {
  'directions': boolean;
  'solars': boolean;
  'transits': boolean;
  'prog_natal': boolean;
}

export interface ISynastryMapsState { [key: string]: boolean };

const prognosticsMapIndicatorsDefault = (): IPrognosticsMapsState => ({ directions: true, 'solars': true, 'transits': true, 'prog_natal': true });
const synastryMapIndicatorsDefault = (): ISynastryMapsState => ({
  'partner1': true,
  'partner2': true,
  'partner3': true,
  'partner4': true,
  'partner5': true,
  'partner6': true,
  'partner7': true,
  'partner8': true,
  'partner9': true,
  'compatibility1': true,
  'compatibility2': true,
  'compatibility3': true,
  'compatibility4': true,
  'compatibility5': true,
  'compatibility6': true,
  'compatibility7': true,
  'compatibility8': true,
  'compatibility9': true,
});


export interface IMapIndicatorState {
  compatibilityReversed: boolean;

  prognosticsExt: IPrognosticsMapsState;
  prognosticsNatal: IPrognosticsMapsState;
  
  partnerExt: ISynastryMapsState;
  partnerNatal: ISynastryMapsState;
  
}

export default class InstrumentsStore {

  private mapIndicator: IMapIndicatorState = observable({
    compatibilityReversed: false,

    prognosticsExt: prognosticsMapIndicatorsDefault(), // for multipla maps
    prognosticsNatal: prognosticsMapIndicatorsDefault(),

    partnerExt: synastryMapIndicatorsDefault(),
    partnerNatal: synastryMapIndicatorsDefault(),

  });

  constructor() {
    makeAutoObservable(this);
  }

  widgetsTab: string | null = null;
  formData: IFormData | null = null;


  setMapCompatibilityReversed(value: boolean): void {
    runInAction(() => {
      this.mapIndicator.compatibilityReversed = value;
      // console.log(`setMapCompatibilityReversed ${value}`)
    })
  }

  setMapIndicatorPrognosticsExt(value: boolean, mapMode: PrognosticsMode): void {
    runInAction(() => {
      (this.mapIndicator.prognosticsExt as any)[mapMode] = value;
      this.mapIndicator.prognosticsExt = {... this.mapIndicator.prognosticsExt}
      // console.log(`setMapPrognosticsExt ${value}`)
    })
  }

  setMapIndicatorPrognosticsNatal(value: boolean, mapMode: PrognosticsMode): void {
    runInAction(() => {
      (this.mapIndicator.prognosticsNatal as any)[mapMode] = value;
      this.mapIndicator.prognosticsNatal = {...this.mapIndicator.prognosticsNatal};
      // console.log(`setMapPrognosticsNatal ${value} index - `, mapIndex)
    })
  }

  setMapIndicatorPartnerExt(mode: SynastryMode, value: boolean, mapIndex: number): void {
    runInAction(() => {
      this.mapIndicator.partnerExt[`${mode}`] = value;
      this.mapIndicator.partnerExt = { ...this.mapIndicator.partnerExt };
      // console.log(`setMapPartnerExt - ${JSON.stringify(this.mapIndicator.partnerExt)}`)
    })
  }

  setMapIndicatorPartnerNatal(mode: SynastryMode, value: boolean, mapIndex: number): void {
    runInAction(() => {
      this.mapIndicator.partnerNatal[`${mode}`] = value;
      this.mapIndicator.partnerNatal = { ...this.mapIndicator.partnerNatal };
      // console.log(`setMapPartnerNatal - ${JSON.stringify(this.mapIndicator.partnerNatal)}`)
    });
  }

  get mapIndicatorCurrent(): IMapIndicatorState {
    return this.mapIndicator;
  }

  setFormData(data: Partial<IFormData>) {
    this.formData = {
      ...(this.formData ?? {} as IFormData),
      ...data,
    };
    localStorage.setItem('formData', JSON.stringify(data));
  }

  getFormData() {
    if (this.formData) {
      return this.formData;
    } else {
        const formDataJSON = localStorage.getItem('formData');
        if (formDataJSON) {
          return JSON.parse(formDataJSON);
        }
    }
  }

}
