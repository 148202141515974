import { IFormData, CircleMode, RelocationsMode, ISynastryPartnerData } from "src/libs";

import React from "react";

import {
  NatalIcon,
  HorarIcon,
  SoulIcon,
  CompatibilityIcon,
  PartnerIcon,
  Partner1Icon,
  Partner2Icon,
  Partner3Icon,
  Partner4Icon,
  Partner5Icon,
  Partner6Icon,
  Partner7Icon,
  Partner8Icon,
  Partner9Icon,
  DirectionsIcon,
  SolarsIcon,
  TransitsIcon,
  ProgProgIcon,
  ProgNatalIcon
} from 'src/assets/icons/maps';
import { RelocationIcon } from "src/assets/icons/maps";

const partnerIcons: {
  [key: string]: any,
} = {
  partner1: Partner1Icon,
  partner2: Partner2Icon,
  partner3: Partner3Icon,
  partner4: Partner4Icon,
  partner5: Partner5Icon,
  partner6: Partner6Icon,
  partner7: Partner7Icon,
  partner8: Partner8Icon,
  partner9: Partner9Icon,
};

export const modeMetaData: { [key: string]: {
  title: string;
  showTab: boolean;
  icon?: React.ComponentType;
} } = {
  directions: {
    title: 'astro.directions',
    showTab: false,
    icon: DirectionsIcon,
  },
  horar: {
    title: 'astro.horar',
    showTab: true,
    icon: HorarIcon,
  },
  soul: {
    title: 'astro.formulaSoul',
    showTab: true,
    icon: SoulIcon,
  },
  natal: {
    title: 'astro.natal',
    showTab: true,
    icon: NatalIcon,
  },
  syn_natal: {
    title: 'astro.natal',
    showTab: true,
    icon: NatalIcon,
  },
  transits: {
    title: 'astro.transits',
    showTab: false,
    icon: TransitsIcon,
  },
  solars: {
    title: 'astro.solars',
    showTab: false,
    icon: SolarsIcon,
  },
  prog_natal: {
    title: 'astro.progNatal',
    showTab: false,
    icon: ProgNatalIcon,
  },
  prog_prog: {
    title: 'astro.progProg',
    showTab: false,
    icon: ProgProgIcon,
  },
  relocation_natal: {
    title: 'astro.natal',
    showTab: true,
    icon: NatalIcon,
  },
};

export const isSynNatal = (mode: CircleMode) => /^syn_natal\d*$/.test(mode);

export const isCompatibility = (mode: CircleMode) => /^compatibility\d*$/.test(mode);

export const isPartner = (mode: CircleMode) => /^partner\d+$/.test(mode);

export const isRelocation = (mode: CircleMode) => /^relocation\d+$/.test(mode);

export const prognosticModes = ['directions', 'solars', 'transits', 'prog_natal', /*'prog_prog'*/]; //prognostics!!!!

export const progressionsTitle: { [key: string]: string } = prognosticModes.reduce((acc, mode, idx) => {
  (acc as any)[mode as string] = modeMetaData[mode].title
  return acc;
}, {});

export const isPrognostics = (mode: CircleMode) => prognosticModes.includes(mode);

export const compatibilityToPartner = (mode: CircleMode) => mode.replace('compatibility', 'partner') as CircleMode;

export const getModeNumber = (mode: CircleMode) => isPartner(mode) || isCompatibility(mode) ? +mode.replace(/\D/g, '') : undefined;

export const getModeMetaData = (mode: CircleMode, form?: IFormData) => {

  if(isPartner(mode)) {
    const index = +(mode.slice(-1)) - 1
    const title = form?.partners ? form?.partners[index]?.name : `astro.${mode}`

    return {
      title,
      showTab: true,
      icon: partnerIcons[mode] || PartnerIcon,
    };
  }

  if(isCompatibility(mode)) {
    return {
      title: `astro.${mode}`,
      showTab: true,
      icon: CompatibilityIcon,
    };
  }

  if (isRelocation(mode)) {
    if (mode === 'relocation_natal') {
      return modeMetaData?.[mode] || {};
    } else {
      return {
        title: form?.relocation?.[mode as RelocationsMode]?.place.name.split(',')[0],
        showTab: true,
        icon: RelocationIcon,
      };
    }
  }

  return modeMetaData?.[mode] || {};
}

export const getModeData = (mode: CircleMode, form: IFormData) => {
  if(isPartner(mode) || isCompatibility(mode)) {
    const num = getModeNumber(mode);
    if(!num || !form.partners?.[num - 1]) {
      return null;
    }
    return form.partners[num - 1] as ISynastryPartnerData;
  }

  if(mode === 'horar') {
    return form.horar || null;
  }

  return form?.natal || null;
}

export const getSynastryModes = (form: IFormData) => {
  let synastryModes: CircleMode[] = []

  if(form.partners?.length) {
    synastryModes.push('syn_natal')

    form.partners.forEach((partner: ISynastryPartnerData, index: number) => {
      if(!synastryModes.includes(`partner${index + 1}`)) {
        synastryModes.push(`partner${index + 1}`);
      }
      if(!synastryModes.includes(`compatibility${index + 1}`)) {
        synastryModes.push(`compatibility${index + 1}`);
      }
    });
  }

  return synastryModes
}
