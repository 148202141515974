import { makeAutoObservable, runInAction } from 'mobx';

import api from 'src/api';
import { IAuth, IUser, IUserRegion, nowISOString, IPlace } from 'src/libs';
import dayjs from 'dayjs';

export const emptyPlace: IPlace = {
  name: '',
  lat: 0,
  lon: 0
};

export default class User {

  public auth: IAuth = {
    id: -1,

    firstName: '',
    lastName: '',
    avatarUrl: '',
    // @ts-ignore
    gender: null,
    // @ts-ignore
    levelOfAstrology: null,

    sessionId: -1,
    permissions: null,
    showWelcomeInstruments: false,
    region: {
      id: -1,
      userId: -1,
      default: false
    },

    autoPayment: false,
    expiryDate: '',
    isUnderConstruction: false,
    
    name: '',
    notifications: [],
    showWelcome: false,
    showWelcomeNewInterface: false,
    showDawnOnboarding: false,
    subscriptions: [],
    theme: '',
    token: { access: '', refresh: '' },
    confirmations: {
      privacyAndTerms: false,
    },
    language: '',
    nps2Visited: []
  }

  public profile: Omit<IUser, 'id' | 'sessionId' | 'permissions' | 'showWelcomeInstruments'> = {
    firstName: '',
    lastName: '',
    // @ts-ignore
    levelOfAstrology: null,
    avatarUrl: '',
    birth: {
      dateTime: nowISOString(),
      gmt: null,
      place: emptyPlace
    },
    email: '',
    phoneNumber: '',
    // @ts-ignore
    gender:	null,
    partner: '',
    // @ts-ignore // TOFIX TODO
    hasIndiSubscription: false
    // hasSynastryUsage:	false,
    // hasEmotionsUsage:	false
  }

  constructor() {
    makeAutoObservable(this);
  }


  private readonly levelName: {[key: string]: string} = {
    '0': "chronos.app.settings.bedinning",
    '1': "chronos.app.settings.amateur",
    '2': "chronos.app.settings.professional"
  };

  get levelOfAstrologyName() {
    return this.profile.levelOfAstrology ? this.levelName[this.profile.levelOfAstrology] : '';
  }

  get isLimitedAccess(): boolean {
    const isExpiry = Boolean(this.auth.subscriptions?.length) && dayjs().isAfter(dayjs(this.auth.expiryDate));
    return isExpiry;
  }

  get region() {
    return this.auth.region
  }

  setRegion(region: IUserRegion) {
    this.auth.region = region;
  }

  updateProfile(newValues: Partial<IUser>) {
    runInAction(() => {
      this.profile = {
        ...this.profile,
        ...newValues
      }
    });
  }

  updateBirth(date: string) {
    this.profile.birth.dateTime = date;
  }

  updateBirthPlace(place: IPlace) {
    this.profile.birth.place = place;
  }

  updateShowWelcomeInstruments(value: boolean) {
    this.auth.showWelcomeInstruments = value;
  }

  async getUserProfile(): Promise<IUser> {
    try {
      const result = await api.profile();
      runInAction(() => {
        this.profile = result;
      });
      return result;
    } catch (e) {
      console.log('fetch user profilr err -', e);
      throw e;
    }
  }
}
