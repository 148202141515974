import React, { useRef, memo } from 'react';
import styled, { css } from 'styled-components';

import Planets from './Planets';
import Signs from './Signs';
import Houses from './Houses';

import { CloseIcon as CloseIconSVG } from 'src/assets/icons/system';
import Theme from 'src/theme';
import spaceImage from 'src/assets/illustrations/backgrounds/space.jpg';
import spaceLightImage from 'src/assets/illustrations/backgrounds/space-light.png';

export const housesIcons = ['I','II','III','IV','V','VI','VII','VIII','IX','X','XI','XII'];

import { IWidgetData } from './../Widget';
import Aspects from './Aspects';
import { useTranslation } from 'src/i18n/useTranslation';
import { isPartner } from '../../utils';

const screenWidth = screen.width || window.innerWidth;
const zoom = screenWidth / window.innerWidth;
export const scrollOffset = (98 + 28) / zoom;

function Prompts(props: {
  data: IWidgetData;
  onClose: () => void;
  onChanged: (key: string, value: any) => void;
}) {

  const scrollRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    scrollRef.current && (scrollRef.current.scrollTop = 0);
  }, [props.data.prompt]);

  const scrollTo = (position: string | number) => {
    let top = 0;
    if (typeof position === 'number') {
      top = position;
    } else if (typeof position === 'string') {
      const positionEl: HTMLElement = scrollRef.current!.querySelector(position)!;
      top = positionEl.offsetTop - scrollOffset;
    }
    scrollRef.current!.scrollTo({ top, behavior: 'smooth' });
  };

  const props_ = {
    ...props,
    onScroll: scrollTo,
    ref: scrollRef
  };

  const category: string = props.data.prompt?.category || '';

  if (
    (category === 'objects' || category === 'signs' || category === 'houses') &&
    (props.data.widgetMode !== 'natal' && isPartner(props.data.widgetMode))
  ) return <></>;

  return <Container>
    {category === 'objects' && <Planets {...props_}></Planets>}
    {category === 'signs' && <Signs {...props_}></Signs>}
    {category === 'houses' && <Houses {...props_}></Houses>}
    {category === 'aspects' && <Aspects {...props_}></Aspects>}
  </Container>;
}

const Container = styled.div`
  //max-width: 31rem;
  width: 100%;
  height: 100%;
  position: relative;

  background: var(--workspace-widget-background);
  box-shadow: var(--workspace-box-shadow);
  margin-top: 6.5rem !important;
`;

export const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--accent-blue);
    border: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--accent-blue-hover);
  }
`;

export const CloseIcon = styled(CloseIconSVG)`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 3rem;
  right: 3rem;
  color: var(--text-secondary);
  cursor: pointer;
`;

export const HeaderContainer = styled.div`
  margin-bottom: 1.75rem;
`;

export const Header = styled.header`
  padding-top: 3rem;
  padding-bottom: 1.75rem;

  color: var(--text-primary);

  & svg {
    color: var(--text-third);
    height: 1.5rem;
    margin-right: 0.75rem;
  }

  & h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 150%;
  }
`;

export const PlanetHeader = styled(Header) `
  min-height: 32rem;
  background-image: linear-gradient(to top, var(--workspace-widget-background), rgba(255,255,255,0)), url(${() => Theme.current === 'dark' ? spaceImage : spaceLightImage});
  background-size: cover;
`;

export const SignHeader = styled(Header) `
  min-height: 32rem;
  ${p => Theme.actual === 'dark' && css`
    background-image: linear-gradient(to top, var(--workspace-widget-background), rgba(255,255,255,0)), url(${() => Theme.actual === 'dark' ? spaceImage : spaceLightImage});
  `}

  ${p => Theme.actual === 'light' && css`
    background-image: linear-gradient(to bottom, #8e9fa9, rgba(255,255,255,0)), url(${() => Theme.actual === 'dark' ? spaceImage : spaceLightImage});
  `}
  
  background-size: cover;
`;

export const SubHeader = styled.div`
  display: flex;
  justify-content: center;
  color: var(--text-secondary);
  margin-top: 0.375rem;

  & > div {
  display: flex;
  align-items: center;

    & > svg {
      color: var(--text-third);
      margin-right: 0.5rem;
      margin-bottom: 0.128rem;
    }

    &:first-child {
      margin-right: 1.25rem;
    }
  }
`;

export const TextIcon = styled.span`
  color: var(--text-third);
  font-size: 1.375rem;
  margin-right: 0.675rem;
`;

export const TextIconWithoutMargin = styled.span`
  color: var(--text-third);
  font-size: 1.375rem;
`;

export const Menu = styled.nav`
  & ul {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;

    & li {
      padding: 0.5rem 0.75rem;
      margin-right: 1.125rem;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &:hover, &.active {
        background: var(--element-neutral);
        border-radius: 0.375rem;
      }
    }
  }
`;

export const TextBlock = styled.div<{ full?: boolean }>`
  margin: 0;
  margin-bottom: 2.125rem;
  font-size: 1.125rem;
  line-height: 1.625rem;
  color: var(--text-secondary);

  ${props => !props.full && css`
    margin-left: 2rem;
    margin-right: 2rem;
  `}

  & h3 {
    margin: 0;
    margin-bottom: 0.75rem;
    font-weight: 600;
    font-size: 1.5rem;
    color: var(--text-primary);
  }

  & ul {
    margin: 0;
    padding: 0;
    padding-left: 2rem;

    & li {
      margin-bottom: 0.75rem;
    }
  }
`;

const Footer = styled.div`
  margin: 0 3rem;
  padding: 3rem 3.5rem;
  
  border-top: 1px solid var(--element-neutral);
  text-align: center;
  user-select: text;

  & > h3 {
    margin: 0;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
  }

  & > span {
    display:block;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--text-secondary);
    margin-bottom: 1.125rem;
  }

  & a {
    color: inherit !important;
  }
`;

export const FooterComponent = () => {
  const { t } = useTranslation();
  return <Footer id="conf-block">
    <h3>{t("astro.chronos.app.prompts.findMistakes")}</h3>
    <span>{t("astro.chronos.app.prompts.emailUs")} <a href="mailto:support@chronos.mg">support@chronos.mg</a> {t("astro.chronos.app.prompts.weWillFix")}</span>
  </Footer>
  };

export default memo(Prompts);
