import _ from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';

import api from 'src/api';
import { ICustomizationData, ICustomization } from 'src/libs'

import defaultPersonalization, { defaultComputedRadius } from 'src/components/Circle/settings/personalization';

export default class PersonalizationStore {
  defaultSettings = defaultPersonalization;
  computedRadius = defaultComputedRadius;
  current = defaultPersonalization;
  list: ICustomization[] = [];
  loading: boolean = false;
  private _activeId: number | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async init(userId: number, activeId: number): Promise<void> {
    this._activeId = null;
    if (!this.list.length) {
      await this.getListFromApi(userId);
    }
    await this.getCurrent(activeId);
  }

  async getListFromApi(userId: number): Promise<ICustomization[]> {
    this.list = (await api.getPersonalizationList(userId)).map(p => {
      // FIXME:
      // @ts-ignore
      if (p.data.data) {
        // FIXME:
        // @ts-ignore
        p.data = p.data.data;
      }
      return p;
    });
    return this.list;
  }

  getActiveId(): number | null {
    return this._activeId;
  }

  async setActiveId(id: number): Promise<void> {
    this._activeId = +id;
    this.loading = true;
    
    this.setCurrent(this.list.find(el => el.id === this._activeId)!.data);
    
    runInAction(() => {
      setTimeout(() => {
        this.loading = false;
      }, 500)
    })
  }

  async getCurrent(activeId: number): Promise<ICustomizationData> {
    if (this._activeId === null) {
      this._activeId = activeId;

      if (this._activeId === undefined) {
        const id = this.list[0]?.id ?? 0;
        this._activeId = id;
      }

      this.setCurrent(this.list.find(el => el.id === this._activeId)?.data || this.list[0]?.data || this.defaultSettings);
    }

    return this.current;
  }

  setDefault(): void {
    const { id } = this.list.find(el => el.data.default)!;
    if (id === this._activeId) { return }
    this.setActiveId(id);
  }

  reset(): void {
    this.setCurrent(_.cloneDeep(this.defaultSettings));
  }

  setCurrent(newSettings: ICustomizationData): void {
    this.current = newSettings;

    const { radius } = this.current.circle;

    // Internal
    const aspect = radius.internal;
    const objects = (radius.zodiacsInternal - aspect) * radius.modifier.objects;
    const zodiacs = (radius.zodiacsExternal - radius.zodiacsInternal) * radius.modifier.zodiacs;

    // const coefficients = 1 / (aspect + objects + zodiacs);

    const newZodiacsExternal = radius.zodiacsExternal;
    const newZodiacsInternal = radius.zodiacsExternal - zodiacs;
    const newInternal = newZodiacsInternal - objects;

    // External
    const aspectExt = radius.internalExt;
    const objectsIntExt = (radius.zodiacsInternalExt - aspectExt) * radius.modifier.objects;
    const zodiacsExt = (radius.zodiacsExternalExt - radius.zodiacsInternalExt) * radius.modifier.zodiacs;
    const objectsExtExt = (radius.external - radius.zodiacsExternalExt) * radius.modifier.objectsExt;

    // const coefficientsExt = 1 / (aspectExt + objectsIntExt + zodiacsExt + objectsExtExt);

    const newExternalExt = radius.external;
    const newZodiacsExternalExt = newExternalExt - objectsExtExt;
    const newZodiacsInternalExt = newZodiacsExternalExt - zodiacsExt;
    const newInternalExt = newZodiacsInternalExt - objectsIntExt;

    this.computedRadius = {
      internal: newInternal,
      zodiacsInternal: newZodiacsInternal,
      zodiacsExternal: newZodiacsExternal,
      external: newZodiacsExternal,

      internalExt: newInternalExt,
      zodiacsInternalExt: newZodiacsInternalExt,
      zodiacsExternalExt: newZodiacsExternalExt
    };
  }
}
