import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import { Button } from 'src/libs';

import { checkGenChanged } from 'src/utils';
import api, { IFormData, isValidISOString } from 'src/api';
import { switcherHook } from 'src/hooks';
import { ImportIcon } from 'src/assets/icons/system';
import { parseZetString } from './Import/zet';

import Confirmation from 'src/components/Confirmation';
import { useTranslation } from 'src/i18n/useTranslation';

export default function ImportZetModal(props: {
  form: IFormData;
  onClose(): void;
  onSubmit(formData: IFormData): void;
}): JSX.Element {
  const [zetValue, setZetValue] = useState<IFormData | null>(null);
  const [rawZetValue, setRawZetValue] = useState('');
  const [isDisableInput, _] = useState(false);
  const [errorForm, setErrorForm] = useState(false);

  const showConfirmation = switcherHook(false);

  const location = useLocation();
  const { t } = useTranslation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const rawZetValueHandler = useCallback((value: string) => {
    const parsedZet = parseZetString(value, props.form);

    setZetValue(parsedZet);
    setRawZetValue(value);

    setErrorForm(false);
  }, [searchParams]);

  const sendFormData = useCallback((data: IFormData) => {
    showConfirmation.off();

    if (!isValidISOString(data.natal.dt)) {
      setErrorForm(true);
      return;
    }

    if (data.id === -1) {
      props.onSubmit(data);
      props.onClose();
      return;
    }

    api.updateForm(data).then(() => {
      props.onSubmit(data);
      props.onClose();
    }).catch(err => {
      console.error(err);
      setErrorForm(true);
    });
  }, [props, showConfirmation]);

  const sendHandler = useCallback(() => {
    if (!zetValue) {
      setErrorForm(true);
      return;
    }

    const {
      gen,
      natal: { dt, place: _place }
    } = props.form;

    const zetNatal = zetValue.natal;

    if (!zetValue.name) { zetValue.name = props.form.name }

    if (checkGenChanged(gen, zetNatal.dt, dt, zetNatal.place, _place)) {
      showConfirmation.on();
    } else {
      sendFormData(zetValue);
    }
  }, [props.form, sendFormData, showConfirmation, zetValue]);

  const getClipboardText = useCallback(async (): Promise<void> => {
    const rawText = await navigator.clipboard?.readText?.();

    rawText && rawZetValueHandler(rawText);
  }, [rawZetValueHandler]);

  const submitHandler = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      sendHandler();
    }
  }, [sendHandler]);

  const escapeHandler = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      props.onClose();
    }
  }, [props]);

  useEffect(() => {
    getClipboardText();

    window.addEventListener('keyup', escapeHandler);
    return () => {
      window.removeEventListener('keyup', escapeHandler);
    };
  }, [escapeHandler, getClipboardText]);

  useEffect(() => {
    window.addEventListener('keyup', submitHandler);
    return () => {
      window.removeEventListener('keyup', submitHandler);
    };
  }, [submitHandler, zetValue]);

  // const getValueFromZet = (raw: string, parsed: IFormData | null): string => {
  //   const splitedRaw = raw.split(';').map(el => el.trim());
  //
  //   return parsed
  //     ? `${parsed?.natal.place.name}, ${splitedRaw[5]} ${splitedRaw[6]}, ${splitedRaw[1]}, ${splitedRaw[2]}`
  //     : rawZetValue;
  // };

  return (
    <Wrapper>
      <Overlay onClick={() => props.onClose()} />
      <OverlaySibiling />
      <Content errorForm={errorForm}>
        <ImportIcon />
        <Input disabled={isDisableInput} value={rawZetValue} onChange={e => rawZetValueHandler(e.target.value)} />
        <Button
          onClick={sendHandler}
          bordered size="small" color="transparent"
        >
          {'Enter'}
        </Button>
        {errorForm && <ErrorText>{t("chronos.app.components.importZetModal.wrongData")}</ErrorText>}
      </Content>

      {showConfirmation.value && zetValue && <Confirmation
        title={t("base.resetFormations")}
        submitText={t("base.confirm")}
        onSubmit={() => sendFormData(zetValue)}
        onCancel={showConfirmation.off}
      >
        <ConfirmationText>
          {t("base.resetFormationsInfo")}
        </ConfirmationText>
      </Confirmation>
      }
    </Wrapper>
  );
}

const modalFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;

  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 100;
`;

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  backdrop-filter: brightness(0.9) blur(3px);

  z-index: 1;
`;

const Input = styled.input`
  appearance: none;
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
  flex: 1;
  margin: 0 1.25rem;

  font-size: 1.25em;
  color: var(--text-primary);
`;

const Content = styled.div<{ errorForm?: boolean }>`
  position: relative;
  max-width: 786px;
  width: 100%;
  height: 72px;
  align-items: center;
  padding: 1.5rem 1.6rem;
  box-sizing: border-box;
  justify-content: center;
  ${props => props.errorForm && css`border: 2px solid var(--colors-red);`};
  
  display: flex;

  z-index: 1;

  background-color: var(--bg-700);
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.14);
  border-radius: 8px;

  animation: ${modalFadeIn} 0.2s ease-in-out;
  
  & > button {
    width: auto !important;
  }

  & > svg {
    width: 1.5rem;
  }
`;

const ErrorText = styled.span`
  position: absolute;
  bottom: -1.5rem;
  color: var(--colors-red);
`;

const ConfirmationText = styled.p`
  margin: 1em 0;

  color: var(--text-third);
  font-size: 0.875rem;
`;

const OverlaySibiling = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: var(--background);
  z-index: -1;
  left: 0;
  top: 0;
  filter: blur(10px);
  opacity: 0.8;
`;
