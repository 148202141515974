import React, { Suspense } from 'react';
import styled from 'styled-components';
import SectionSwitch from 'src/pages/Settings/sections/SectionSwitch';
import SideBar from 'src/pages/Settings/Sidebar';

import store from 'src/store';
import { history } from 'src/router';
import { useKeyHook } from 'src/hooks';
import { getDeviceDetect } from 'src/utils';
import { observer } from 'mobx-react';

const { isTablet } = getDeviceDetect();

export default observer(function AppSettings() {

  useKeyHook('Escape', {
    onKeyDown() {
      history.push(store.lastAppPath);
    }
  }, [store]);

  // const [isReady, setReady] = React.useState<boolean>(true);

  const userId = store.settings.user.auth.id;

  // React.useLayoutEffect(() => {
  //   if(store.activeAstroProfile) return;

  //   const init = async () => {
  //     await store.initSettings(userId);
  //     setReady(Boolean(store.activeAstroProfile));
  //   }
  //   init();
  
  // }, [store.activeAstroProfile]);

  return (
    <AppSettingsPage>
      {<SideBar />}
      <Container className="page-container">
        <Suspense fallback={<LoadingLayer>{'Loading...'}</LoadingLayer>}>
          {<SectionSwitch />}
        </Suspense>
      </Container>
    </AppSettingsPage>
  );
});

const AppSettingsPage = styled.div`
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
`;

const Container = styled.div`
  position: relative;
  width: ${isTablet ? '73vw' : '78vw'};
  margin-left: ${isTablet ? '27vw' : '22vw'};
  /* margin-bottom: 70px; */
  min-height: 100%;
  background-color: var(--workspace-background);
`;

const LoadingLayer = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;

  color: var(--text-third);
`;
