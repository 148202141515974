import React, { useCallback, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import ym from 'react-yandex-metrika';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { switcherHook } from 'src/hooks';
import store from 'src/store';

import MainGroup from 'src/components/EditForm/MainGroup';
import Confirmation from 'src/components/Confirmation';

import {
  NatalIcon,
  SynastryIcon,
  PrognosticsIcon,
  HorarIcon
} from 'src/assets/icons/maps';

import {
  PlusIcon,
  CloseIcon,
  PersonIcon
} from 'src/assets/icons/system';
import { checkGenChanged, getIntervalWithThisGmt, getFixedGmt } from 'src/utils';
import { useTranslation } from 'src/i18n/useTranslation';
import { 
  Button, 
  CircleLoader, 
  IBaseData, 
  IFixedGmt, 
  IFormData, 
  IHorarData, 
  ISynastryPartnerData, 
  Input, 
  defaultHousesSystem, 
  getGMT, 
  makeForm, 
  show, 
  toLocaleISOString 
} from 'src/libs';

import { observer } from 'mobx-react';


type Nodes = 'natal' | 'synastry' | 'prognostics' | 'horar';


const info: {
  [key: string]: {
    text: string;
    icon: any;
  };
} = {
  natal: {
    text: "astro.natal",
    icon: <NatalIcon />
  },
  synastry: {
    text: "astro.synastry",
    icon: <SynastryIcon />
  },
  prognostics: {
    text: "astro.prognostics",
    icon: <PrognosticsIcon />
  },
  horar: {
    text: "astro.horar",
    icon: <HorarIcon />
  }
};

function defaultBaseData(): IBaseData {
  return {
    dt: toLocaleISOString(dayjs().toISOString()),
    gmt: dayjs().utcOffset() / 60, // GMT
    autoGmt: true,
    // @ts-ignore
    place: { name: undefined }
  };
}

interface IFormError {
  node: Nodes | null;
  text: string;
}

interface FormProps {
  formId: number;
  onClose?(): void;
  onSubmit(form: IFormData): void;
}

export function activeHousesSystem() {
  return store.activeAstroProfile?.housesSystem || defaultHousesSystem;
}

export function _defaultForm(form = makeForm({ name: '', natal: defaultBaseData(), housesSystem: activeHousesSystem() } )) {
  delete form.synastry;
  delete form.prognostics;
  delete form.horar;
  delete form.rectification;
  
  form.partners = [];

  return form;
}

export default observer(function EditForm(props: FormProps) {

  const { astro: astroStore } = store;
  const { isLimitedAccess } = store.settings.user;

  // console.log(`makeForm - `, _defaultForm())

  const [node, setNode] = React.useState<Nodes>('natal');
  const [form, setForm] = React.useState<IFormData>(_defaultForm());
  const [errors, setErrors] = React.useState<IFormError[]>([]);
  const [invalidDateTime, setInvalidDateTime] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(false);
  const showConfirmation = switcherHook(false);
  const [natal, setNatal] = React.useState(defaultBaseData());
  const [fixedGmt, setFixedGmt] = React.useState<{ [key: string]: IFixedGmt }>({ ...astroStore.profiles.find(p => p.mutable)?.fixedGmt } || {});
  const [autoGmt, setAutoGmt] = React.useState<boolean | undefined>(undefined);
  const [fixedStars, setFixedStars] = React.useState<{ [key: string]: any }>({ ...store.activeAstroProfile?.fixedStars });
  const [useNatalPlace, setUseNatalPlace] = React.useState(
    form.useNatalPlace === undefined
      ? astroStore.profiles.find(p => p.mutable)?.useNatalPlace
      : form.useNatalPlace
  );
  const [loading, setLoading] = React.useState(false);
  const [calcRangeForGmtPromise, setCalcRangeForGmtPromise] = React.useState<Promise<any> | null>(null);
  const [showAspects, setShowAspects] = React.useState<boolean>(store.settings.profile.ui.instruments.cardSettingsForms.horar.showAspects);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  // const [isSettingsReady, setSettingsReady] = React.useState(false);
  const tInit = useTranslation();
  const { t } = tInit;
  const userId = store.settings.user.auth.id;

  const data = form[node] as ISynastryPartnerData;

	const containerRef = useRef<HTMLFormElement>(null)

  React.useEffect(() => {
    if (props.formId != -1) {
      store.dashboard.fetchForm(Number(props.formId)).then(form => {
        if (form) {
          setForm(form);
          setNatal({
            dt: form.natal.dt,
            place: form.natal.place,
            gmt: form.natal.gmt
          });
          setNode('natal');
        }
        setLoading(false);
      });
    }    

  }, [props.formId]);
  

  const onSubmitForm = () => {
    form.housesSystem = activeHousesSystem();
    delete form.soul;

    // уведомляем о смене autoGmt
    if (autoGmt !== undefined) {
      let text, type;
      if (autoGmt === false) {
        text = 'Персональные настройки GMT включены';
        type = 'warning'
      } else {
        text = 'Персональные настройки GMT отключены';
        type = 'info'
      }

      show({
        // @ts-ignore
        type,
        closable: true,
        timeout: 5000,
        text
      });
    }

    props.onSubmit(form);

    store.settings.profile.updateUi(ui => {
      ui.instruments.cardSettingsForms.horar.showAspects = showAspects;
    });

    ym('reachGoal', 'click-create-and-open-client');
    showConfirmation.off();
  };

  const updateAstroProfile = async (newValue: { [key: string]: any }) => {
    let profile = cloneDeep(store.activeAstroProfile!);
    const myProfile = store.astro.profiles.find(p => p.mutable)!;

    profile = {
      ...profile,
      ...newValue
    };

    if (profile.id !== myProfile.id) {
      profile.id = myProfile.id;
      profile.title = myProfile.title;
      profile.mutable = true;
    }

    // console.log('before update profile - ', profile)
    return store.astro.updateProfile(profile);
  }

  // @ts-ignore
  const { name = '', originalName, lat, lon, originalLat, originalLon, showUserCoordinates } = data.place as IPlaceEx;
  const placeIsBad = name && name !== originalName && (lat === originalLat && lon === originalLon);

  const onSubmit = async (evt: any) => {
    evt && evt.preventDefault();

    const errors: IFormError[] = [];

    if (form.name.trim().length == 0) {
      errors.push({
        node: null,
        text: t("chronos.app.titleInput")
      });
    }

    const checkNode = (node: Nodes) => {
      const data = form[node] as IBaseData;
      const { place } = data;
      const prognosticsUseNatal = (node === 'prognostics') && form.useNatalPlace;
      // @ts-ignore
      const { name = '', originalName, lat, lon, originalLat, originalLon, showUserCoordinates } = place as IPlaceEx;

      if (invalidDateTime) {
        errors.push({
          node,
          text: t("chronos.app.inputBirthDate")
        });
      } else

      if (name.trim().length === 0 && !prognosticsUseNatal) {
        errors.push({
          node,
          text: t("chronos.app.noLocation")
        });
      }


      if (placeIsBad) {
        errors.push({
          node: 'natal',
          text: t("chronos.app.choosePlaceFromList")
        });
      }

      if ((('originalLat' in place && 'originalLon' in place) || showUserCoordinates) && (!lat || !lon) && !prognosticsUseNatal) {
        errors.push({
          node,
          text: t("chronos.app.noCoordinates")
        });
      }
    };

    checkNode('natal');
    form.synastry && checkNode('synastry');
    form.prognostics && checkNode('prognostics');
    form.horar && checkNode('horar');

    if (form.horar && (form.horar.question || '').trim().length == 0) {
      errors.push({
        node: 'horar',
        text: t("chronos.app.questionInput")
      });
    }

    setErrors(errors);

    if (errors.length) { return }

    if (
      props.formId != -1 &&
      checkGenChanged(form.gen, natal.dt, form.natal.dt, natal.place, form.natal.place)
    ) {
      showConfirmation.on();
    } else {
      await updateAstroProfile({ fixedGmt: { ...fixedGmt } });
      onSubmitForm();
    }
  };

  const onChangeNode = (node: Nodes) => () => {
    if (!form[node]) {
      const partners = node === 'synastry'
      ? [defaultBaseData()]
      : form.partners

      setForm(form => ({
        ...form,
        [node]: defaultBaseData(),
        partners
      }));
    }

    setNode(node);
  };

  const onChangeName = (ev: any) => {
    setForm(form => ({
      ...form,
      name: ev.target.value,
    }));
  };

  const onChangeCosmogram = (cosmogram: boolean) => {
    setForm(form => ({
      ...form,
      cosmogram
    }));

    ym('reachGoal', 'toggle-cosmogram');
  };

  const onChangeUseNatalPlace = (val: boolean) => {
    setUseNatalPlace(val);

    setForm(form => ({
      ...form,
      useNatalPlace: val
    }));
  }

  const onChange = (key: string) => (value: any, payload?: any) => { // paylod - приходит

    if (key === 'place') {
      const dateTime = form[node]?.dt

      const partners = node === 'synastry'
      ? [{
          ...form.partners?.[0],
          [key]: value,
        gmt: dateTime && (value.lat != undefined && value.lon != undefined) && getGMT(dateTime, value.lat, value.lon)
        }]
      : form.partners

      // @ts-ignore
      setForm(form => ({
        ...form,
        [node]: {
          ...form[node],
          [key]: value,
          gmt: dateTime && (value.lat != undefined && value.lon != undefined) && getGMT(dateTime, value.lat, value.lon)
        },
        partners
      }));

      // console.log(`EditForm onChange ${key} -`, value)
      return
    }

    if (key === 'dt') {
      const place = form[node]?.place
      const partners = node === 'synastry'
      ? [{
          ...form.partners?.[0],
          [key]: value,
          gmt: place?.lat && place?.lon ? getGMT(value, place.lat, place.lon) : dayjs().utcOffset() / 60
        }]
      : form.partners

       // @ts-ignore
      setForm(form => ({
        ...form,
        [node]: {
          ...form[node],
          [key]: value,
          gmt: place?.lat && place?.lon
            ? getGMT(value, place.lat, place.lon)
            : (form[node]?.gmt || dayjs().utcOffset() / 60)
        },
        partners
      }));
      setInvalidDateTime(false);
      return
    }

    const partners = node === 'synastry'
    ? [{
        ...form.partners?.[0],
        [key]: value,
      }]
    : form.partners

    // @ts-ignore
    setForm(form => ({
      ...form,
      [node]: {
        ...form[node],
        [key]: value,
      },
      partners
    }));

  };

  const onChangeGMT = (val: number, auto: boolean) => {
    const currentGmt = val;
    const autoGmt = auto;

    const currentNode: ISynastryPartnerData = data;
    const nodeDt = currentNode?.dt;
    const nodePlace = currentNode?.place;
    const { lat, lon } = nodePlace || {};

    let calculatedGmt: number | null = null;

    if (!lat || !lon || !nodeDt) return;

    if (autoGmt) {
      setDisableForm(false);
      //@ts-ignore
      calcRangeForGmtPromise?.cancel();
      const { key: dtKey } = getFixedGmt({ dt: nodeDt!, lat: lat, lon: lon, data: fixedGmt });
      const { [dtKey]: deletedKey, ...newFixedGmt } = fixedGmt;
      setFixedGmt(newFixedGmt);

      calculatedGmt = getGMT(nodeDt, lat, lon);
      nodeFixedGmt && setAutoGmt(true);

    } else if (lat && lon && nodeDt) {
      setDisableForm(true);
      setShowLoader(true);
      const promiseFn = getIntervalWithThisGmt(nodeDt!, currentGmt, nodePlace!);
      setCalcRangeForGmtPromise(promiseFn);

      promiseFn.then(({ dateKey, start, end, gmt, place }) => {
        setFixedGmt(state => ({
          ...state,
          [dateKey]: {
            range: [start, end],
            gmt,
            lat: place.lat,
            lon: place.lon
          }
        }))
        setDisableForm(false);
        setShowLoader(false);
      })
      setAutoGmt(false);
    }

    setForm(form => ({
      ...form,
      [node]: {
        ...form[node],
        gmt: calculatedGmt || currentGmt,
        autoGmt: autoGmt
      }
    }));

    // console.log(`onChange gmt - node: ${node} gmt:${currentGmt} autoGmt: ${autoGmt} fixedGmt: ${JSON.stringify(fixedGmt)}`)
  };

  const onDelete = () => {
    const partners = node === 'synastry'
    ? undefined
    : form.partners

    setForm(form => ({
      ...form,
      [node]: undefined,
      partners
    } as any)); // FIXME: any
    setNode('natal');
  };

  const changeShowAspects = useCallback(() => {
    setShowAspects(showAspects => !showAspects);
  }, [showAspects]);

  const nodeFixedGmt = React.useMemo((): number | null => {
    const data = (form as any)[node];
    if (!data.place) return null;
    const { gmt: userFixedGmt } = getFixedGmt({
      dt: data.dt,
      lat: data.place.lat,
      lon: data.place.lon,
      data: fixedGmt
    });

    userFixedGmt && setForm(form => ({
      ...form,
      [node]: {
        ...form[node],
        gmt: userFixedGmt,
      }
    }));

    return userFixedGmt
  }, [
    form.natal.dt,
    form.synastry?.dt,
    form.prognostics?.dt,
    form.horar?.dt,
    form.natal.place,
    form.synastry?.place,
    form.prognostics?.place,
    form.horar?.place,
    node,
    fixedGmt
  ]);


  // const onFixedStars = React.useCallback(async (mode: string, list: string[], showWithObjects: boolean) => {
  //   setFixedStars(state => {
  //     return {
  //       ...state,
  //       [mode]: {
  //         list,
  //         showWithObjects
  //       }
  //     }
  //   });

  //   const profileId = await updateAstroProfile({ fixedStars: { ...fixedStars, [mode]: { list, showWithObjects } } }, Boolean('saveToBackend'))

  //   store.setActiveAstroProfileId(profileId)
  // }, [fixedStars, node])


  return (
    <>
      {(props.formId == -1 || !loading) &&
        <Container onSubmit={onSubmit} ref={containerRef}>
          <aside>
            <Avatar>
              <PersonIcon fill={form.color} />
            </Avatar>

            <NameInput
              value={form.name}
              onChange={onChangeName}
              placeholder={t("chronos.app.titleInput")}
            />

            <ul>
              <Mode
                active={node === 'natal'}
                onClick={onChangeNode('natal')}
                hasErrors={errors.some(e => e.node == 'natal')}
              >
                <NatalIcon />
                {t("astro.natal")}
              </Mode>

              { !isLimitedAccess && 
                <>
                  <Mode
                    disabled={form.cosmogram}
                    active={node === 'synastry'}
                    onClick={form.cosmogram ? () => { } : onChangeNode('synastry')}
                    hasErrors={errors.some(e => e.node == 'synastry')}
                  >
                    <SynastryIcon />
                    {t("astro.synastry")}
                    {!form.synastry && <Plus />}
                  </Mode>
    
                  <Mode
                    active={node === 'prognostics'}
                    onClick={onChangeNode('prognostics')}
                    hasErrors={errors.some(e => e.node == 'prognostics')}
                  >
                    <PrognosticsIcon />
                    {t("astro.prognostics")}
                    {!form.prognostics && <Plus />}
                  </Mode>
    
                  <Mode
                    active={node === 'horar'}
                    onClick={onChangeNode('horar')}
                    hasErrors={errors.some(e => e.node == 'horar')}
                  >
                    <HorarIcon />
                    {t("astro.horar")}
                    {!form.horar && <Plus />}
                  </Mode>
                </>
              }
            </ul>

            <Buttons>
              <Button
                onClick={props.onClose}
                color="transparent"
              >{t("base.cancel")}</Button>
              <Button type="submit" disabled={disableForm || placeIsBad}>{t("base.save")}</Button>
            </Buttons>

            <Errors>
              {errors
                .filter(e => !e.node || e.node == node)
                .map(e => <li key={e.text}>{e.text}</li>)
              }
            </Errors>
          </aside>
          <main>
            <CloseButton onClick={props.onClose}><CloseIcon /></CloseButton>
            <header>
              {info[node].icon}
              {t(info[node].text)}

              {node !== 'natal' &&
                <div onClick={onDelete}>
                  {t("chronos.app.delete")}
                  <CloseIcon />
                </div>
              }
            </header>

            <div className="hr" />

            <section>
              {node == 'horar' &&
                <Label>
                  <span>{t("base.question")}</span>
                  <Input
                    value={(data as IHorarData).question}
                    onChange={onChange('question')}
                    placeholder={t("base.questionInfo")}
                  />
                </Label>
              }

              <MainGroup
                className="main-group"
                type={node}
                name={data?.name}
                place={data.place}
                dateTime={data.dt}
                gmt={data.gmt ?? 0}
                setName={onChange('name')}
                fixedGmt={nodeFixedGmt}
                setPlace={onChange('place')}
                setDateTime={onChange('dt')}
                setInvalidDateTime={() => { setInvalidDateTime(true) }}
                setGMT={onChangeGMT}
                cosmogram={form.cosmogram}
                setCosmogram={onChangeCosmogram}
                showAspects={showAspects}
                setShowAspects={changeShowAspects}
                gender={data.gender}
                setGender={onChange('gender')}
                natal={form.natal}
                form={form}
                useNatalPlace={useNatalPlace}
                onChangeUseNatalPlace={onChangeUseNatalPlace}
								parentContainerRef={containerRef}
              />
            </section>

            {showLoader && <LoaderLayout><StyledCircleLoader /></LoaderLayout>}

          </main>
        </Container>
      }

      {showConfirmation.value &&
        <Confirmation
          title={t("base.resetFormations")}
          submitText={t("base.confirm")}
          onSubmit={onSubmitForm}
          onCancel={showConfirmation.off}
        >
          <ConfirmationText>{t("base.resetFormationsInfo")}</ConfirmationText>
        </Confirmation>
      }
    </>
  );
});

const Container = styled.form`
  font-size: 1rem;
  display: flex;
  position: relative;
  color: var(--text-secondary);

  height: 100%;

  & > aside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3em 1.5em;

    background: var(--aside-gradient);
    background-blend-mode: color, luminosity;
    backdrop-filter: blur(32px);

    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;

    & > ul {
      margin: 3em 0;
      padding: 0;
      list-style-type: none;
    }
  }

  & > main {
    display: flex;
    position: relative;
    flex-direction: column;

    background-color: var(--bg-400);
    padding: 6em;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    & > header {
      display: flex;

      svg {
        width: 1.25em;
        height: 1.25rem;
        margin-right: 0.5em;
      }

      margin-bottom: 0.5em;

      div {
        display: flex;
        align-items: center;
        color: var(--colors-red);
        margin-left: auto;

        font-size: 0.8em;

        user-select: none;
        cursor: pointer;

        svg {
          margin-left: 0.5em;
          fill: var(--colors-red);
          width: 1em;
        }
      }
    }

    & > .hr {
      border-top: 1px solid var(--element-neutral);
      margin-bottom: 1em;
    }

    & > section {
      width: 20em;
    }
  }
`;

const Label = styled.div`
  width: 100%;
  font-size: 0.75rem;
  margin-bottom: 1rem;

  & > span {
    display: block;
    margin-bottom: 0.4rem;
  }

  & ::-webkit-scrollbar {
    width: 2px;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: var(--accent-blue);
    border: none;
  }
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-500);
  border-radius: 50%;
  width: 3em;
  height: 3em;

  svg {
    width: 1.4em;
  }

  margin-right: auto;
  margin-bottom: 2em;
`;

const NameInput = styled.input`
  appearance: none;
  outline: none;
  border: none;
  background: transparent;
  width: 100%;

  font-size: 1.25em;
  color: var(--text-primary);
`;

const Mode = styled.li<{ disabled?: boolean; active?: boolean; hasErrors?: boolean }>`
  display: flex;
  align-items: center;

  cursor: pointer;
  user-select: none;

  color: var(--text-primary);

  transition: all 0.3s;
  margin: 0.75em 0;

  & > svg:first-child {
    margin-right: 0.5em;
    width: 1.25em;
    height: 1.25rem;
  }

  opacity: 0.5;

  :hover {
    opacity: 1;
  }

  ${props => props.hasErrors && css`
    color: var(--colors-red);
  `}

  ${props => props.active && css`
    &, &:hover {
      opacity: 1;
    }
  `}

  ${props => props.disabled && css`
    &, &:hover {
      cursor: inherit;
      opacity: 0.5;
    }
  `}
`;

const Buttons = styled.div`
  display: flex;
  align-items: flex-start;

  & :first-child {
    margin-right: 0.5em;
  }
`;

const Errors = styled.ul`
  list-style-type: none;
  margin: 0;
  color: var(--colors-red);
  font-size: 0.9em;
`;

const Plus = styled(PlusIcon)`
  width: 1.5em;
  margin-left: 0.5em;
`;

const ConfirmationText = styled.p`
  margin: 1em 0;

  color: var(--text-third);
  font-size: 0.875rem;
`;

const CloseButton = styled.button`
  position: absolute;
  appearance: none;
  border: none;
  background: transparent;
  color: var(--icon-secondary);
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const LayoutAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const LoaderLayout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--disabled-layer-color);
  animation: ${LayoutAnimation} 0.2s ease-in-out;
`;

const StyledCircleLoader = styled(CircleLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
`;
