import { makeAutoObservable, runInAction } from 'mobx';
import SettingsStore from 'src/pages/Settings/store/index';
import DashboardStore from './Dashboard';
import AstroStore from './AstroStore';
import PromoStore from './PromoStore';
import MeetingStore from './MeetingStore';
import ConsultationsStore from './ConsultationsStore';
import InstrumentsStore from './InstrumentsStore';
import CoursesStore from './CoursesStore';
import NPSStore from './NPS';
import { changeLanguage } from 'i18next';
import { ITranslator } from 'src/i18n/useTranslation';
import { languagesList } from 'src/i18n/i18n';
import { INotification } from 'src/libs';

import astro from 'src/astro';

import { cloneDeep } from 'lodash';

class Store {
  shareId: number | null = null;
  notifications: INotification[] = [];
  astro = new AstroStore();
  settings = new SettingsStore();
  dashboard = new DashboardStore();
  promo = new PromoStore();
  meeting = new MeetingStore();
  consultations = new ConsultationsStore();
  instruments = new InstrumentsStore();
  courses = new CoursesStore();
  nps = new NPSStore();
  language = localStorage.getItem('i18nextLng') ?? 'ru';
  cloudUrl = process.env.REACT_APP_S3;

  constructor() {
    makeAutoObservable(this);
    const switchLanguage = (ev: KeyboardEvent) => {
      if (ev.code === 'KeyL' && ev.shiftKey && (ev.ctrlKey || ev.metaKey)) {
        const currentIndex = languagesList.indexOf(this.language);
        this.language = languagesList[(currentIndex + 1) % languagesList.length];
        changeLanguage(this.language);
      }
    }
    window.addEventListener('keydown', switchLanguage)
  }

  //
  private _currentFormlimitedAccess = false;
  get currentFormlimitedAccess() {
    return this._currentFormlimitedAccess;
  }

  set currentFormlimitedAccess(value: boolean) {
    this._currentFormlimitedAccess = value;
  }

  //
  private _isLoading = false;
  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value: boolean) {
    this._isLoading = value;
  }

  basicPage: string = "/dashboard";

  setBasicPage(loc: any, action: string) {
    if(loc.pathname.indexOf("/consultations") > -1) {
      this.basicPage = "/consultations";
    }else if(loc.pathname.indexOf("/dashboard") > -1){
      this.basicPage = "/dashboard";
    }

    if (loc.pathname !== '/') {
      localStorage.setItem('lastVisitedPath', location.pathname + location.search);
    }
  }

  getLastVisitedPath() {
    const defaultPath = '/dashboard'
    const path = localStorage.getItem('lastVisitedPath');
    if (path === '/') return defaultPath;
    return localStorage.getItem('lastVisitedPath') || defaultPath;
  }

  appSwitchShow = false;
  setAppSwithShow(value: boolean) {
    this.appSwitchShow = value;
  }

  lastAppPath = localStorage.getItem('lastAppPath') ?? '/dashboard';
  setAppPath() {
    const path = location.pathname + location.search;
    localStorage.setItem('lastAppPath', path);
    this.lastAppPath = path;
  }

  supportNotifyShow = false;
  setSupportNotifyShow(value: boolean) {
    this.supportNotifyShow = value;
  }

  get activeAstroProfile() {
    const { profiles } = this.astro;
    return profiles?.find(p => p.id === this.settings.profile.activeProfileId) || profiles?.find(p => p.id === 0);
  }

  get myProfile() {
    const { profiles } = this.astro;
    return profiles?.find(p => p.id === -1);
  }

  updateLanguage() {
    this.language = localStorage.getItem('i18nextLng') ?? 'ru';
  }

  async setActiveAstroProfileId(id: number, fromUser: boolean = false) {

    const MAP_ZET_ID = 3;

    const isNewMyProfile = id == -1; 
    const syncMapsWithProfile = this.settings.profile.syncMaps;

    // переключились первый раз на Мой Профиль
    if (isNewMyProfile) {
      this.settings.profile.setActiveCustomizationId(MAP_ZET_ID)
      
      id = await this.astro.updateProfile(cloneDeep(this.myProfile!))
      
      await this.astro.init(this.settings.user.auth.id, Boolean('force'))
    }

    const currentProfile = this.astro.profiles?.find(profile => profile.id === id);

    // Если выбрали перый раз на Мой профиль (id == -1)
    let currentCustomId = isNewMyProfile ? MAP_ZET_ID : currentProfile?.idCustomization;

    if(currentCustomId && syncMapsWithProfile && !isNewMyProfile) {
      this.settings.profile.setActiveCustomizationId(currentCustomId)
    }

    this.settings.profile.activeProfileId = id;


    if (!syncMapsWithProfile) return;

    if (currentCustomId) {
      this.settings.personalization.setActiveId(currentCustomId);
    } else {
      this.settings.personalization.setDefault();
    }
    


  }

  synchronizeProfile() {
    // if (!this.settings.profile.syncMaps) return

    const profileId = this.settings.profile.activeProfileId;
    const currentProfile = this.astro.profiles?.find(profile => profile.id === profileId);

    // Если переключились перый раз на Мой профиль (id == -1)
    let currentCustomId = currentProfile?.idCustomization;

    if(currentCustomId) {
      this.settings.profile.setActiveCustomizationId(currentCustomId, true)
      this.settings.personalization.setActiveId(currentCustomId);
    }
  }

  async initCommon(userId: number, t: ITranslator) {
    this.promo.init(t);
  }

  async initSettings(userId: number, t?: ITranslator) {
    await this.settings.init(userId);
    await this.astro.init(userId);

    if(this.activeAstroProfile) {
      runInAction(() => {
        // @ts-ignore
        astro.settings = this.activeAstroProfile
      })
    } 
  }
}

export default new Store();
