import { MouseEvent } from "react";
import { BinIcon, ClipboardIcon, CopyIcon, ShevronIcon } from "src/assets/icons/system";
import { useTranslation } from "src/i18n/useTranslation";
import { Tooltip } from "src/libs";
import styled, { css } from "styled-components";

interface SectionProps {
	title: string;
  isOpened: boolean;
  switch(): void;
  children: any;
  icon?: any;
  leftChevron?: boolean;
  removable?: boolean;
  copySuccess?: boolean;
  pasteSuccess?: boolean;
  onDelete?(): void;
  onCopy?(): void;
  onPaste?(): void;
  isLast?: boolean;
  className?: string;
}

export function Section(props: SectionProps) {
  const { t } = useTranslation();
	const onDelete = (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>) => {
		if (props.onDelete && props.removable) {
			e.stopPropagation()
			props.onDelete()
		}
	}

  return <Group
    className={props.className}
    isOpened={props.isOpened}
    leftChevron={props.leftChevron}
    isLast={props.isLast}
		removable={props.removable}
  >
    {
			props.leftChevron
				?	<header>
		        <ShevronIcon className='chevron' onClick={props.switch} />
		        <span>{props.title}</span>
            <ClipboardContainer>
            <Tooltip  text={props.copySuccess ? t("chronos.app.components.editForm.copied") : t("chronos.app.components.editForm.copyData")}>
            <CopyIcon onClick={props.onCopy} />
            </Tooltip>
            <Tooltip text={props.pasteSuccess ? t("chronos.app.components.editForm.inserted") : t("chronos.app.components.editForm.insertData")}>
            <ClipboardIcon onClick={props.onPaste} />
            </Tooltip>
            <Tooltip text={t("chronos.app.delete")}>
						<BinIcon className='bin' onClick={(e) => onDelete(e)}/>
            </Tooltip>
            </ClipboardContainer>
	      	</header>
				:	<header onClick={props.switch}>
		        <span>{props.title}</span>
		        {props.icon || <ShevronIcon className='chevron'/>}
	      	</header>
    }

    {props.isOpened && props.children}
  </Group>;
}

export const Group = styled.div<{
  isOpened: boolean;
  leftChevron?: boolean;
  isLast?: boolean;
  removable?: boolean;
}>`
  margin-bottom: 0rem;
  padding-bottom: 0.125rem;

  ${props => props.isOpened && css`
    padding-bottom: 1.25rem;
  `}

  ${props => props.isLast ? '' : 'border-bottom: 1px solid var(--element-neutral)'};

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.125rem;

    & > span {
      font-size: 1rem;
      color: var(--text-primary);

      ${props => props.leftChevron && css`
        width: 80%;
        margin-right: auto;
				margin-left: 0.5rem;
      `}
    }

    & > svg {
      width: 1.5rem;
      height: 1.5rem;
			fill: var(--icon-third);

			&.bin {
				${props => props.removable && css`
          fill: var(--text-secondary);
        `}
			}

      &.chevron {
        cursor: pointer;
        ${props => props.isOpened && css`
          transform: rotate(180deg);
        `}
      }
    }
  }
`;
const ClipboardContainer = styled.div`
  display: flex;
  span {
    display: flex;

   svg {
    margin-left: 0.75rem;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
   }
  }
`;