import React, {useEffect, useState, useMemo, useRef} from 'react';
import styled, {css} from 'styled-components';

import { MoreMenu, IDropdownOption, isSynastry, isPrognostics, isRelocation, CircleMode, EditFormType } from 'src/libs';

import store from 'src/store';
import { clickOutsideHook, ReactMemo,switcherHook, useKeyHook} from 'src/hooks';
import { checkGenChanged, clearURLSearchParams, getDeviceDetect} from 'src/utils';
import dispatcher from 'src/dispatcher';
import {nowLocalISOString} from '../../../utils';
import { compatibilityToPartner, getModeData, getModeMetaData, isCompatibility, isPartner } from '../utils';

import { ChevronsUpDownIcon } from 'src/assets/icons/system';
import {ISelectedItem} from 'src/types';
import TimeControl, {switcherMode, TimeControlType} from './TimeControl';
import {TimeLineMode} from './TimeControl/TimeLine';
import Attw from 'src/components/AdvancedTooltipWrapper';
import EditForm from 'src/components/EditForm';
import PopupNotify from 'src/ui/PopupNotify';
import Select from 'src/ui/Select';
import InstrumentButton from 'src/components/InstrumentButton';
import { useTranslation } from 'src/i18n/useTranslation';
import { ChevronUpDownIcon } from 'src/assets/icons/system/24';
import WidgetsButtons from '../WidgetsButtons';
import Confirmation from "../../../components/Confirmation";
import api, { IFormData, IWidgetsItem } from 'src/api';


import {
  NatalIcon,
  SynastryIcon,
  PrognosticsIcon,
  HorarIcon,
  SoulIcon,
  CompatibilityIcon,
  RelocationIcon
} from 'src/assets/icons/maps';

import { RefreshIcon, ShevronDownIcon } from 'src/assets/icons/system';
import { observer } from 'mobx-react';

const relocationIcon = <svg><RelocationIcon /></svg>

const majorModes = ['natal', 'synastry', 'prognostics', 'horar', 'soul', 'relocation'];

const { isTablet, isIPad } = getDeviceDetect();

const screenWidth = screen.width || window.innerWidth;

function getModeIndex(showEdit: string | null, modes: CircleMode[]) {
  if (showEdit) return majorModes.indexOf(showEdit);

  if (isSynastry(modes)) return 1;
  if (isPrognostics(modes)) return 2;
  if (modes.includes('horar')) return 3;
  if (modes.includes('soul')) return 4;
  if (isRelocation(modes)) return 5;


  return 0;
}
interface IProps {
  modes: CircleMode[];
  form: IFormData;
  currentMode: CircleMode;
  widgets: IWidgetsItem[];
  auxModes: CircleMode[];
  autoPrediction: boolean;
  showEdit: string | null;
  limitedAccess: boolean;
  limitedAccessAction: () => void;
  onModeChanged(mode: CircleMode | CircleMode[]): void;

  onFormEdit(form: IFormData): void;

  showWidget(name: string): void;

  showAllWidgets(): void;

  onDateTimeChanged(type: TimeControlType, dt: string, clearGen?: boolean): void;

  onReplace(from: string, to: string): void;

  onHold?(id: string, v: boolean): void;

  setShowEdit(val: string | null): void;

}

const menuModes: (ISelectedItem & {
  mode: string;
})[] = [
  {
    id: 0,
    Icon: NatalIcon,
    title: "astro.natal",
    mode: 'natal'
  },
  {
    id: 1,
    Icon: SynastryIcon,
    title: "astro.synastry",
    mode: 'synastry'
  },
  {
    id: 2,
    Icon: PrognosticsIcon,
    title: "astro.prognostics",
    mode: 'prognostics'
  },
  {
    id: 3,
    Icon: HorarIcon,
    title: "astro.horar",
    mode: 'horar'
  },
  {
    id: 4,
    Icon: SoulIcon,
    title: "astro.formulaSoul",
    mode: 'soul'
  },
  {
    // RU
    id: 5,
    Icon: RelocationIcon,
    title: "chronos.app.settings.relocation",
    mode: 'relocation'
  },
];

const getIconByName = (name: string) => {
  const targetMode = menuModes.find(m => m.mode === name);
  return targetMode?.Icon;
}

export default observer(function Footer(props: IProps) {
  const {showEdit, setShowEdit} = props;

  const [showTimeLineBase, setShowTimeLineBase] = useState<TimeLineMode>(null);
  const [showTimeLineExt, setShowTimeLineExt] = useState<TimeLineMode>(null);
  const [showSecondTimeControl, setShowSecondTimeControl] = useState<boolean>(false);
  const { t } = useTranslation();
  const leftGroupRef = useRef<HTMLDivElement>(null);
  const centerGroupRef = useRef<HTMLDivElement>(null);

  const showIsLockedPopup = switcherHook(false);
  const showIsLockedLeftPopup = switcherHook(false);
  const showConfirmation = switcherHook(false);
  const showRefreshMap = !isCompatibility(props.currentMode)

  const isShare = (new URL(window.location.href)).searchParams.has('share');
  const isLocked = !props.form.access.isPersonal;
  

  const partnerAuxModes = useMemo(() => props.auxModes.filter(isPartner), [props.auxModes]);
  const compatibilityAuxModes = useMemo(() => props.auxModes.filter(isCompatibility), [props.auxModes]);
  const noCompatibilityAuxModes = useMemo(() => {
    if (props.form?.cosmogram && props.auxModes.includes('transits')) {
      return props.auxModes.filter(m => !isCompatibility(m) && (m === 'transits'))
    } else return props.auxModes.filter(m => !isCompatibility(m))
  }
  , [props.auxModes, props.form.cosmogram]);

  const partnerModes = useMemo(() => props.modes.filter(isPartner), [props.modes]);
  const compatibilityModes = useMemo(() => props.modes.filter(isCompatibility), [props.modes]);
  const showTimeControlsSelector = isSynastry(props.modes) && !(partnerModes?.length === 2 && props.modes.length === 2) && props.modes.length > 1;
  const { mapIndicatorCurrent: MapIndicator } = store.instruments

  useEffect(() => {
    setShowTimeLineExt(null);
    setShowTimeLineBase(null);
  }, [props.modes])

  const closeEdit = () => setShowEdit(null);

  clickOutsideHook(leftGroupRef, () => { closeEdit(); showIsLockedLeftPopup.off() });
  clickOutsideHook(centerGroupRef, () => { showIsLockedPopup.off(); });

  const onModeChanged = (mode: CircleMode, add?: boolean) => {
    if (props.modes.length === 1 && props.modes[0] === mode) return;

    props.onModeChanged(add ? mode : [mode]);
    setShowEdit(null);
  };

  const timeLineSynastryHandler = () => {
    if (showTimeLineExt === 'second') {
      if(props.modes.some(isPartner) || props.modes.some(isCompatibility)){
        setShowTimeLineExt(null);
        setShowTimeLineBase('day');
      } else {
        setShowTimeLineExt('day');
      }
      return;
    }
    if (showTimeLineBase === 'second') {
      if(props.modes.some(isPartner) || props.modes.some(isCompatibility)){
        setShowTimeLineExt('day');
        setShowTimeLineBase(null);
      } else {
        setShowTimeLineBase('day');
      }
      return;
    }

    (showTimeLineExt || props.modes.some(isPartner))
      ? setShowTimeLineExt((s) => switcherMode[String(s)])
      : setShowTimeLineBase((s) => switcherMode[String(s)]);
    return;
  }

  const timeLinePrognosticsHandler = () => {
    if (showTimeLineExt === 'second') {
      setShowTimeLineExt(null);
      setShowTimeLineBase('day');
      return;
    }
    if (showTimeLineBase === 'second') {
      setShowTimeLineExt('day');
      setShowTimeLineBase(null);
      return;
    }

    showTimeLineExt
      ? setShowTimeLineExt((s) => switcherMode[String(s)])
      : setShowTimeLineBase((s) => switcherMode[String(s)]);
    return;
  }

  const timeLineHandler = () => {
    const hasExt = isSynastry(props.modes) || isPrognostics(props.modes);

    if (hasExt) {
      if(isSynastry(props.modes)) {
        timeLineSynastryHandler();
      } else if(isPrognostics(props.modes)) {
        timeLinePrognosticsHandler();
      }
      return;
    }

    setShowTimeLineBase(switcherMode[String(showTimeLineBase)]);
  };

  useKeyHook([
    'KeyE',
    'KeyD'
  ], {
    onKeyDown: key => {
      if (store.settings.user.isLimitedAccess) return
      if (key === 'KeyE' && !isLocked) {
        showEdit === 'natal' ? setShowEdit(null) : setTimeout(() => {setShowEdit('natal')}, 0);
      }
      if (key === 'KeyD' && !isLocked) timeLineHandler();
    }
  }, [
    showEdit,
    showTimeLineExt,
    showTimeLineBase,
    props.modes
  ], {
    ignoreActiveInput: false,
    stopPropagation: false
  });

  const modeIndex = getModeIndex(showEdit, props.modes);
  const workWidgets = props.widgets.filter(item => !['devplan', 'autoprediction', 'interpretation'].includes(item.id));
  const paidBuildings = props.widgets.filter(item => ['devplan', 'autoprediction', 'interpretation'].includes(item.id));
  const showNatalTimeControl = isPrognostics(props.currentMode) && props.modes.some(mode => (MapIndicator.prognosticsNatal as any)[mode])
    || (isSynastry(props.currentMode) && MapIndicator.partnerNatal)

  const [baseTimeData, secondTimeData, BaseTimeControlIcon, SecondTimeControlIcon] = useMemo(() => {

    const { mapIndicatorCurrent: mapIndicator } = store.instruments

    const MapIndicatorNatalON = mapIndicator.prognosticsNatal || mapIndicator.partnerNatal;

    if (props.modes.includes('horar')) {
      return [props.form.horar!, null, HorarIcon, null];
    }
    if (props.modes.includes('soul')) {
      return [props.form.natal, null, NatalIcon, null];
    }
    if(isPrognostics(props.modes)) {
      const result = [props.form.natal, props.form.prognostics, NatalIcon, PrognosticsIcon];
      return [...result];
    }
    if(isSynastry(props.modes)) {
      if(props.modes.length === 2 && partnerModes.length === 2) {
        return [
          getModeData(partnerModes[0], props.form),
          getModeData(partnerModes[1], props.form),
          getModeMetaData(partnerModes[0]).icon,
          getModeMetaData(partnerModes[1]).icon,
        ];
      } else {
        if(isCompatibility(props.currentMode)) {
          const compatibility = [getModeData(props.currentMode, props.form), props.form.natal]
          const icons = [getModeMetaData(compatibilityToPartner(props.currentMode)).icon, getModeMetaData('natal').icon]

          if (mapIndicator.compatibilityReversed) {
            compatibility.reverse()
            icons.reverse()
          }

          return [...compatibility, ...icons];
        }
        if(isPartner(props.currentMode)) {
          return [
            getModeData(props.currentMode, props.form),
            props.form.natal,
            getModeMetaData(props.currentMode).icon,
            NatalIcon,
          ];
        }
      }
    }

    return [props.form.natal, null, NatalIcon, null];
  }, [
    props.modes,
    props.currentMode, 
    store.instruments.mapIndicatorCurrent.compatibilityReversed,
    store.instruments.mapIndicatorCurrent.prognosticsExt,
    store.instruments.mapIndicatorCurrent.prognosticsNatal,
    props.form
  ]);

  const getBaseTimeId = () => props.modes.includes('horar') ? 'horar' : 'natal';

  const getSecondTimeId = () =>
    isSynastry(props.modes)
      ? 'synastry'
      : 'prognostics';

  const handleRefresh = (): void => {
    const isoDate = nowLocalISOString();
    if (showSecondTimeControl || (!isTablet && secondTimeData)) {
      props.onDateTimeChanged('ext', isoDate);
    } else {
      props.onDateTimeChanged('base', isoDate);
    }
  };

  const onSubmitHandler = () => {
    handleRefresh()
    showConfirmation.off()
  }

  const modeMenu: (IDropdownOption & { mode: string; })[] = [
    {
      value: 0,
      label: "astro.natal",
      icon: <NatalIcon style={{width: '1rem', height: '1rem'}}/>,
      mode: 'natal',
      action: () => onModeChanged('natal'),
      visible: true,
      disabled: false
    },
    {
      value: 1,
      icon: <SynastryIcon style={{ width: '1rem', height: '1rem' }} />,
      label: "astro.synastry",
      mode: 'synastry',
      visible: true,
      disabled: props.limitedAccess,
      action: (item: IDropdownOption) => {
        if (isLocked) {
          showIsLockedLeftPopup.on();
          return;
        }

        if (item.disabled) {
          props.limitedAccessAction();
          return
        }

        if (props.form.partners?.length! > 0) {
          onModeChanged('partner1');
        } else {
          setShowEdit('synastry');
        };
      },
    },
    {
      value: 2,
      icon: <PrognosticsIcon style={{ width: '1rem', height: '1rem' }} />,
      label: "astro.prognostics",
      mode: 'prognostics',
      disabled: props.limitedAccess,
      action: (item: IDropdownOption) => {
        if (item.disabled) {
          props.limitedAccessAction();
          return
        }
        if (props.form.prognostics) onModeChanged('directions');
        else {
          if (isLocked) {
            showIsLockedLeftPopup.on();
            return;
          }
          setShowEdit('prognostics');
        };
      }
    },
    {
      value: 3,
      icon: <HorarIcon style={{ width: '1rem', height: '1rem' }} />,
      label: "astro.horar",
      mode: 'horar',
      disabled: props.limitedAccess,
      action: (item: IDropdownOption) => {
        if (item.disabled) {
          props.limitedAccessAction();
          return
        }
        if (props.form.horar) onModeChanged('horar');
        else {
          if (isLocked) {
            showIsLockedLeftPopup.on();
            return;
          }
          setShowEdit('horar')
        };
      }
    },
    {
      value: 4,
      icon: <SoulIcon style={{ width: '1rem', height: '1rem' }} />,
      label: "astro.formulaSoul",
      mode: 'soul',
      disabled: props.limitedAccess,
      action: (item: IDropdownOption) => {
        if (item.disabled) {
          props.limitedAccessAction();
          return
        }
        onModeChanged('soul')
      }
    }, 
    {
      // RU
      value: 5,
      icon: <RelocationIcon style={{ width: '1rem', height: '1rem' }} />,
      label: "chronos.app.settings.relocation",
      mode: 'relocation',
      action: (item: IDropdownOption) => {
        if (item.disabled) {
          props.limitedAccessAction();
          return
        }
        onModeChanged('relocation_natal')
      },
      disabled: props.limitedAccess
    },
  ];

  const modeOptions = useMemo(() => {
    return modeMenu.map(item => {
      const _item: any = item;
      item.label = t(item.label!)

      item.visible = !(
        item.value !== 0 && item.value !== 2 && props.form.cosmogram ||
        item.value >= 3 && item.value !== 5 && (
          !props.form.access.permissions.includes('PRO') ||
          !store.settings.user.auth.permissions?.canUsePro
        )
      );

      item.color = (
        item.value === modeIndex
      ) ? 'var(--accent-blue)' : 'inherit'

      _item.badge = (
        item.value === 1 && !props.form.partners?.length ||
        item.value === 2 && !props.form.prognostics ||
        item.value === 3 && !props.form.horar ||
        item.value === 5 && !props.form.relocation
      ) ? <span style={{ fontSize: '1.5rem', fontWeight: 500, marginLeft: 'auto' }}>+</span> : undefined;

      return item;
    });
  }, [props.form, props.modes]);

  const onRefreshHandler = () => {
    
    if (isLocked) {
      showIsLockedPopup.on();
      return;
    }
    
    if (props.limitedAccess) {
      props.limitedAccessAction?.();
      return;
    }
    
    const {
      gen,
      natal: {dt, place: _place}
    } = props.form
    
    const isoDate = nowLocalISOString();
    const hasGen = checkGenChanged(gen, isoDate, dt, props.form.natal.place, _place)
    const natalOrSoul = (el: string) => el === 'natal' || el === 'soul'
    const isNatalOrSoul = props.modes.some(natalOrSoul);

    isNatalOrSoul && hasGen ? showConfirmation.on() : handleRefresh()
  }

  

  const getMode = (type: string) => {
    let mode: EditFormType = 'natal'
    const { compatibilityReversed } = store.instruments.mapIndicatorCurrent;
    
    if (type === 'base') {
      if (isPartner(props.currentMode)) {
        mode = 'synastry'
      }

      if (isCompatibility(props.currentMode) && !compatibilityReversed) {
        mode = 'synastry'
      }

      if (props.modes.includes('horar')) {
        mode = 'horar'
      }

    } else {
      if (isCompatibility(props.currentMode)) {
        if (compatibilityReversed) {
          mode = 'synastry'
        } else {
            mode = 'natal'
        }
      } else {
          mode = 'prognostics'
      }
    }

    return mode
  }


  return (
    <Container noTime={isLocked && !props.form.access.showPersonalData} className="no-print">
      <LeftGroup ref={leftGroupRef}>
        {<MoreMenu
          options={modeOptions}
          button={<ModesButton up={true}><ShevronDownIcon/></ModesButton>}
          position="top-right"
          openClass="open-menu"
          detectMobile={() => false}
        />}
        <EditFormWrapper>
          
          <InstrumentButton
            id='natal'
            isActive={true}
            onHold={() => props.onHold?.('natal', true)}
            onHoldEnd={() => props.onHold?.('natal', false)}
            hasData={true}
            onEdit={id => setShowEdit(id === showEdit ? null : id)}
            isLocked={isLocked}
            activeStyle='color'
            icon={getIconByName('natal')}
          />

          {showEdit === 'natal' && <EditForm
            type="natal"
            onClose={closeEdit}
            form={props.form}
            onSubmit={props.onFormEdit}
            currentMode={props.currentMode}
          />}
        
        </EditFormWrapper>


        {(!props.modes.includes('natal') || !!showEdit && showEdit !== 'natal') && 
          <EditFormWrapper>
            <Attw text={["astro.natal", "astro.synastry", "astro.prognostics", "astro.horar", "astro.formulaSoul", "chronos.app.settings.relocation"][modeIndex]}>
              <InstrumentButton
                id={majorModes[modeIndex]}
                isActive={true}
                hasBorder={true}
                hasData={[true, !!props.form.synastry || !!props.form.partners?.length, !!props.form.prognostics, !!props.form.horar, true, Object.keys(props.form.relocation ?? {}).length > 1][modeIndex]}
                onEdit={showEdit || !props.modes.includes('soul') ?
                  id => setShowEdit(id === showEdit ? null : id) :
                  undefined
                }
                isLocked={isLocked}
                icon={getIconByName(majorModes[modeIndex])}
              />
            </Attw>
            {showEdit && showEdit !== 'natal' && <EditForm
              type={showEdit as EditFormType}
              onClose={closeEdit}
              form={props.form}
              onSubmit={props.onFormEdit}
              currentMode={props.currentMode}
            />}
          </EditFormWrapper>
        }


        { screenWidth && screenWidth < 1280
            ? props.auxModes?.length
                ? <Select
                    isTop={true}
                    hideSelected={true}
                    selected={props.auxModes
                      .map((m, i) => props.modes?.includes(m) ? i : -1)
                      .filter(id => id >= 0)
                    }
                    options={props.auxModes.map((m, i) => ({
                      id: i,
                      Icon: getModeMetaData(m)?.icon,
                      title: t(getModeMetaData(m)?.title ?? ''),
                      action: (item) => {onModeChanged(m)},
                      rightIcon: () => null,
                    }))}
                    onSelect={() => {}}
                    hideKeyIcon
                    closeOnClickOutside={true}
                    closeOnSelect={true}
                  />
                : null
            : <>
                {
                  noCompatibilityAuxModes
                    .map(m =>
                      <Attw
                        text={t(getModeMetaData(m, props.form)?.title ?? '')}
                        key={`tw_aux_${m}`}
                        align="left"
                      >
                        <InstrumentButton
                          key={`aux_${m}`}
                          id={m}
                          onClick={(ev) => {
                            let correctId = m;
                            if (m as string === 'synastry') { correctId = 'compatibility1' }
                            else if (m as string === 'prognostics') { correctId = 'directions' }
                            onModeChanged(correctId as CircleMode, ev.ctrlKey || ev.metaKey);
                          }}
                          isActive={props.modes.includes(m)}
                          onReplace={props.onReplace}
                          isHighlighted={props.currentMode === m}
                          isLocked={isLocked}
                          icon={getModeMetaData(m)?.icon}
                        />
                      </Attw>
                    )
                }

                {
                  compatibilityAuxModes?.length
                    ? compatibilityAuxModes?.length === 1
                      ? compatibilityAuxModes
                          .map(m =>
                            <Attw
                              text={t(getModeMetaData(m)?.title ?? '')}
                              key={`tw_aux_${m}`}
                              align="left"
                            >
                              <InstrumentButton
                                key={`aux_${m}`}
                                id={m}
                                onClick={(ev) => {
                                  let correctId = m;
                                  if (m as string === 'synastry') { correctId = 'compatibility1' }
                                  else if (m as string === 'prognostics') { correctId = 'directions' }
                                  onModeChanged(correctId as CircleMode, ev.ctrlKey || ev.metaKey);
                                }}
                                isActive={props.modes.includes(m)}
                                onReplace={props.onReplace}
                                isHighlighted={props.currentMode === m}
                                isLocked={isLocked}
                                icon={getModeMetaData(m)?.icon}
                              />
                            </Attw>
                          )
                      : <Select
                          isTop={true}
                          hideSelected={true}
                          selected={compatibilityAuxModes
                            .map((m, i) => props.modes?.includes(m) ? i : -1)
                            .filter(id => id >= 0)
                          }
                          options={compatibilityAuxModes
                            .map((m, i) => ({
                              id: i,
                              Icon: getModeMetaData(m)?.icon,
                              title: t(getModeMetaData(m)?.title ?? ''),
                              action: (item: any, evt: MouseEvent) => {
                                onModeChanged(m)
                              },
                              rightIcon: () => null,
                            }))
                          }
                          isActive={props.modes.some(isCompatibility)}
                          hideKeyIcon
                          closeOnClickOutside={true}
                          closeOnSelect={true}
                          icon={<CompatibilityIcon />}
                          showAsModeButton={true}
                        />
                    : null
                }
              </>
        }

        {showIsLockedLeftPopup.value && <PopupNotify
          buttons={store.settings.user.auth.id === -1 ?
            [{
              title: "base.signIn",
              onClick: (evt: any) => {
                // evt.stopPropagation();
                clearURLSearchParams('share');
                setTimeout(() => api.goToAuth(), 16);
              },
              color: 'var(--accent-blue)'
            }] :
            []
          }
          title={t("chronos.app.instruments.footer.cannotEditTheMap")}
          text={store.settings.user.auth.id === -1 ?
            "chronos.app.instruments.footer.signToChanges" :
            "chronos.app.instruments.footer.saveToChanges"
          }
          align='left'
        />}
      </LeftGroup>

      {(!isLocked || props.form.access.showPersonalData) && (
        <CenterGroup ref={centerGroupRef}>
          { showTimeControlsSelector
              ? <Select
                  isTop={true}
                  hideSelected={true}
                  selected={props.modes.indexOf(props.currentMode)}
                  options={props.modes
                    .map((m, i) => ({
                      id: i,
                      Icon: getModeMetaData(m)?.icon,
                      title: isPartner(m)
                        ? getModeData(m, props.form)?.name || ''
                        : t(getModeMetaData(m)?.title ?? ''),
                      action: () => dispatcher.emit('scrollToMode', m),
                      rightIcon: () => null,
                    }))
                  }
                  onSelect={() => {}}
                  hideKeyIcon
                  closeOnClickOutside={true}
                  closeOnSelect={true}
                  icon={<BaseTimeControlIcon />}
                  extraIcon={<ChevronsUpDownIcon />}
                  showAsModeButton={true}
                />
              : null
          }
          {(showNatalTimeControl && !showSecondTimeControl || !secondTimeData) && baseTimeData && <TimeControl
              type={'base'}
              data={baseTimeData}
              onChange={props.onDateTimeChanged}
              setShowTimeLine={setShowTimeLineBase}
              showTimeLine={showTimeLineBase}
              icon={showTimeControlsSelector ? null : BaseTimeControlIcon} // FIXME: natal, partner1, partner2
              isLocked={isLocked || props.limitedAccess}
              onNotify={showIsLockedPopup.on}
              useHotkeys
              autoPrediction={props.autoPrediction}
              form={props.form}
              onSubmit={props.onFormEdit}
              mode={getMode('base')}
              modes={props.modes}
              limitedAccessAction={props.limitedAccessAction}
          />}


          {!isPartner(props.currentMode) && secondTimeData && ((isTablet && showSecondTimeControl) || !isTablet) && (
            <>
              <TimeControlSplitter/>
              <TimeControl
                type="ext"
                data={secondTimeData}
                onChange={props.onDateTimeChanged}
                setShowTimeLine={setShowTimeLineExt}
                showTimeLine={showTimeLineExt}
                icon={SecondTimeControlIcon} // FIXME: partner2, prognostics (all modes as single)
                isLocked={isLocked}
                onNotify={showIsLockedPopup.on}
                useHotkeys
                autoPrediction={props.autoPrediction}
                form={props.form}
                onSubmit={props.onFormEdit}
                mode={getMode('ext')}
                modes={props.modes}
              />
            </>
          )}

          {isTablet && (isPrognostics(props.modes) || props.modes.some(isCompatibility) || (props.modes.length === 2 && partnerModes.length === 2)) && (
            <>
              <TimeControlSplitter/>
              <InstrumentButton
                id={showSecondTimeControl ? getSecondTimeId() : getBaseTimeId()}
                isActive
                activeStyle="color"
                onClick={() => setShowSecondTimeControl(!showSecondTimeControl)}
                isLocked={isLocked}
                icon={ChevronUpDownIcon}
              />
            </>
          )}

          { showIsLockedPopup.value && 
            <PopupNotify
              buttons={store.settings.user.auth.id === -1 ?
                [{
                  title: "base.signIn",
                  onClick: () => {
                    clearURLSearchParams('share');
                    setTimeout(() => api.goToAuth(), 16);
                  },
                  color: 'var(--accent-blue)'
                }] :
                []
              }
              title={t("chronos.app.instruments.footer.cannotEditTheMap")}
              text={store.settings.user.auth.id === -1 ?
                "chronos.app.instruments.footer.signToChanges" :
                "chronos.app.instruments.footer.saveToChanges"
              }
              align='center'
            />
          }


          {showRefreshMap && <RefreshIconWrapper onClick={onRefreshHandler}><RefreshIcon/></RefreshIconWrapper>}
          
          {showConfirmation.value &&
              <Confirmation
                  title={t("base.resetFormations")}
                  submitText={t("base.confirm")}
                  onSubmit={onSubmitHandler}
                  onCancel={showConfirmation.off}
              >
                <ConfirmationText>
                  {t("base.resetFormationsInfo")}
                </ConfirmationText>
              </Confirmation>
          }
        </CenterGroup>
      )}

      <RightGroup>
        <WidgetsButtons limitedAccess={props.limitedAccess}
          limitedAccessAction={props.limitedAccessAction}
          widgets={[...workWidgets, ...paidBuildings]}
          isLocked={isLocked}
          modes={props.modes}
          onClick={(id) => props.showWidget(id)}
          onReplace={props.onReplace} />
        <SwitchWidgets onClick={props.showAllWidgets} />
      </RightGroup>
    </Container>
  );
});

const Container = styled.footer<{ noTime: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: ${props => props.noTime ? '1.75em' : '0.5em'};
  width: 100%;
  box-sizing: border-box;
  z-index: 7;

  ${isTablet
    ? css`
        position: fixed;
        left: 1rem;

        @media (max-width: 1366px) {
          width: calc(100% - 1rem);
        }

        @media (max-width: 959px) {
          justify-content: space-between;
        }
      `
  : css`
      position: absolute;
    `
  }
`;

const LeftGroup = styled.div`
  position: absolute;
  display: flex;
  left: 1rem;

  & > * {
    margin-right: 0.625rem;
  }

  & > :last-child {
    margin-right: 0;
  }

  ${isTablet
    ? css`
        @media (max-width: 959px) {
          position: static;
        }
      `
  : ''}
`;

const RightGroup = styled.div`
  position: absolute;
  display: flex;
  right: 0.5rem;

  ${isTablet
    ? css`
        @media (max-width: 959px) {
          position: static;
        }
      `
  : ''}
`;

const CenterGroup = styled.div`
  display: flex;
  align-items: center;

  ${isIPad ? css`margin-bottom: 1rem` : ''}
`;

const EditFormWrapper = styled.div`
  position: relative;
`;

const TimeControlSplitter = styled.div`
  width: 0.5rem;
`;

const SwitchWidgets = styled.div`
  height: 2.375rem;
  width: 0.4rem;

  border-radius: 0.25rem;

  cursor: pointer;

  background-color: var(--element-neutral);
`;

const RefreshIconWrapper = styled.div`
  margin-left: 0.5rem;
  margin-right: 5rem;
  color: var(--icon-secondary);
  cursor: pointer;

  svg {
    width: 1.125rem;
  }
`;

const ModesButton = styled.div<{ up: boolean }>`
  position: relative;
  display: flex;
  /* flex: 0.95 1 0%; */
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0px;

  border: 1px solid var(--input-border);
  border-radius: 0.375rem;
  background-color: var(--element-neutral);

  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-third);

  cursor: pointer;

  ${props => props.up && css`
    transform: rotate(180deg);
  `}

  & svg {
    width: 1.5rem;
    height: 1.5rem;
    transition: transform 0.2s ease;
  }

  &.open-menu svg {
    transition: transform 0.2s ease;
    transform: rotate(180deg);
  }
`;

const ConfirmationText = styled.p`
  margin: 1em 0;
  color: var(--text-third);
  font-size: 0.875rem;
`;
