import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react';

import { CircleMode, IMap, PrognosticsMode, RelocationsMode } from 'src/libs';

import { IWidgetData } from '../Widget';

import { ChevronRightIcon } from 'src/assets/icons/system/24';
import { useTranslation } from 'src/i18n/useTranslation';
import { getModeMetaData } from '../../utils';
import store from 'src/store';

const ModeSelectorTabs: React.FC<{
  modes: CircleMode[];
  current: CircleMode;
  data: IWidgetData;
  onChange(key: string, value: any): void;
}> = observer(({
  modes,
  current,
  data,
  onChange = () => {}
}) => {
  const [step, setStep] = useState<number>(0);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const dummyRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<HTMLUListElement>(null);
  const currentRef = React.useRef<HTMLLIElement>(null);
  const prevRef = React.useRef<HTMLDivElement>(null);
  const nextRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { prognosticsExt, prognosticsNatal } = store.instruments.mapIndicatorCurrent;

  React.useEffect(() => {
    dummyRef.current!.style.height = `${containerRef.current!.offsetHeight}px`;
  }, []);

  React.useEffect(() => {
    setStep(0);
  }, [modes]);

  React.useEffect(() => {
    showStep();
  }, [current]);

  React.useEffect(() => {
    showChevrons();
    moveSlider(step);
  }, [step]);

  const showChevrons = () => {
    if (step === 0) {
      prevRef.current!.style.display = 'none';
      if (listRef.current!.offsetWidth > containerRef.current!.offsetWidth) {
        nextRef.current!.style.display = 'block';
      } else {
        nextRef.current!.style.display = 'none';
      }
    } else if (step === 1) {
      prevRef.current!.style.display = 'block';
      nextRef.current!.style.display = 'none';
    }
  };

  const showStep = () => {
    if (currentRef.current) {
      const left = currentRef.current.offsetLeft;
      const width = currentRef.current.offsetWidth;
      const containerWidth = containerRef.current!.offsetWidth;
      if (left + width > containerWidth) { setStep(1) }
      else { setStep(0) }
    }
  };

  const moveSlider = (step: number) => {
    let x = 0;
    if (step === 1) {
      x = listRef.current!.offsetWidth - containerRef.current!.offsetWidth;
    }

    listRef.current!.style.transform = `translateX(-${x}px)`;
  };

  const handleClick = (mode: CircleMode, i: number) => {
    setTimeout(() => {
      showStep();
      showChevrons();
    }, 100);
    onChange('aspects-table-mode', mode);
  };

  return <>
    <Container ref={containerRef}>
      <List ref={listRef}>
        {data.maps.map((map: IMap, i: number) => {
          const name = getModeMetaData(map.mode, data.form).title;

          return <Item
            key={map.mode}
            active={map.mode === current}
            ref={map.mode === current ? currentRef : null}
            onClick={() => { handleClick(map.mode, i) }}
          ><span>{t(name!)}{
              (prognosticsNatal as any)[map.mode as PrognosticsMode] ? ` - ${t('astro.natal') }` : ``
          }</span></Item>;
        })}
      </List>

      <ChevronInCircle type="left" ref={prevRef} onClick={() => setStep(0)}><ChevronRightIcon /></ChevronInCircle>
      <ChevronInCircle type="right" ref={nextRef} onClick={() => setStep(1)}><ChevronRightIcon /></ChevronInCircle>
    </Container>
    <div ref={dummyRef}></div>
  </>;
});

const Container = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  margin-top: 0.25rem;
  list-style: none;
  font-size: 0.875rem;
  color: var(--text-secondary);
  white-space: nowrap;
  width: min-content;
  transition: transform 0.3s;
`;

const Item = styled.li<{active: boolean}>`
  display: inline-block;
  margin-right: 2.25rem;
  cursor: pointer;

  span {
    display: inline-block;
    max-width: 8rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${props => props.active && css`
    color: var(--text-primary);

    &:after{
      display: block;
      content: '';
      margin-top: 1rem;
      height: 0.125rem;
      width: 100%;
      background: var(--accent-blue);
    }
  `}

  &:last-child {
    margin-right: 0;
  }
`;

const ChevronInCircle = styled.div<{type: string}>`
  position: absolute;
  top: 0;

  height: 1.5rem;
  width: 1.5rem;
  background-color: var(--bg-100);
  color: var(--text-primary);
  border-radius: 50%;
  display:none;
  cursor: pointer;

  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  ${props => props.type === 'left' && css`
    left: 0rem;
    transform: scaleX(-1);
  `}

  ${props => props.type === 'right' && css`
    right: 0rem;
  `}
`;

export default ModeSelectorTabs;
