import { show, IShortFormData, toDateTime, Gender } from "src/libs";
import { t } from "i18next";
import { headers } from "./zet";
import XLSX from 'xlsx';

function isNullOrUndefined(v: any) {
  return v === null || v === undefined;
}

export function writeExcelFile(forms: IShortFormData[]) {
  const rows: string[][] = [];

  for (const form of forms) {
    // @ts-ignore
    const { name, place, lat, lon } = form;
    // FIXME: dateTime is an interface
    const { date, time } = toDateTime(form.dateTime);
    // FIXME:
    const gender: Gender = 'female';

    if (isNullOrUndefined(lat) || isNullOrUndefined(lon)) {
      show({
        type: 'error',
        text: t("chronos.app.show.text", { formName: form.name }),
        timeout: 5000,
      });
      return;
    }

    rows.push([
      name,
      date,
      time,
      '0', // FIXME: gmt?.toString() ?? '0',
      place,
      lat.toString(),
      lon.toString(),
      // @ts-ignore
      gender === 'male' ? t("chronos.app.components.import.maleShort") : gender === 'female' ? t("chronos.app.components.import.femaleShort") : ''
    ]);
  }

  const ws = XLSX.utils.aoa_to_sheet([
    headers.map(h => t(h)),
    ...rows
  ]);

  const wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, ws, t("chronos.app.components.import.list.1"));

  XLSX.writeFile(wb, 'db.xlsx');
}